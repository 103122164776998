import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Item,Categories } from '../../shared/order.model';
import { OrderService } from '../../shared/order.service';

export interface KeyValue {
  key: any;
  value: string;
}

@Component({
  selector: 'app-item-detail-dialog',
  templateUrl: './item-detail-dialog.component.html',
  styleUrls: ['./item-detail-dialog.component.scss'],
  providers: [ OrderService ]
})
export class ItemDetailDialogComponent implements OnInit {

  item: Item[] = [];
  categories: Categories[] = [];
  tier = ''

  tierLvl: KeyValue[] = [
    { key: 100, value: "1" },
    { key: 101, value: "1+" },
    { key: 200, value: "2" },
    { key: 300, value: "3" },
    { key: 400, value: "その他" }
  ]

  constructor(
    private matDialogRef: MatDialogRef<ItemDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private orderService: OrderService,
  ) { }

  ngOnInit() {
    console.log(this.data);
    for(let x = 0; x < this.tierLvl.length; x++){
      if(this.data.tier === this.tierLvl[x].key){
        this.tier = this.tierLvl[x].value
      }
    }
  }

  getSuffix(key:number){
    key.toString();
    return this.orderService.sufList[key].value;
  }

  getMnfc(key:number){
    key.toString();
    return this.orderService.mnList[key].value;
  }
}

