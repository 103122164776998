import { COMPANY_NAME } from './../../../shared/data.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { OrderRecords, OrderedItems, allOrders } from '../ordered-item.model';
import { ExportColumns } from './export-columns';
import { ExportUtility } from './export-utility';
import { DashboardService } from '../../shared/dashboard.service';
import { Items } from '../../../item-management/shared/item.model';
import { ItemService } from '../../../item-management/shared/item.service';
import { formatDate } from '@angular/common';
import { StoreService } from '../../../store/shared/store.service';
import { Store } from '../../../store/shared/store.model';
import { Router } from '@angular/router';
import { EditOrder } from 'src/app/shared/edit-order.prov';
import { BadgeSocketService } from 'src/app/shared/badge-socket.service';
import { OrderService } from 'src/app/order/shared/order.service';

export interface CsvFormat {
  item_no: string
  name: string
  qty: string,
  total_amount: string
  blank: string
  wms_id: string
  price: string
  def: string
  order_no: string
}

@Component({
  selector: 'app-order-confirm',
  templateUrl: './order-confirm.component.html',
  styleUrls: ['./order-confirm.component.scss'],
  providers: [ BadgeSocketService ]
})
export class OrderConfirmComponent implements OnInit {

  orderedItems: OrderedItems[] = []; //from ORDERED ITEM
  items: Items[] = []; //from ORDERED ITEM
  tmp: any = JSON.parse(sessionStorage.getItem('currentUser'));
  allOrder: allOrders[] = [];
  store: Store[] = [];
  type: any;
  btn_update_stat: number = 10
  hoped_time: string[] = ['指定なし','午前','午後','']
  task_data : any;
  show_cancel: boolean = false;
  show_btns: boolean = false;
  cancel_txt: string = "None"
  isCancel: boolean = false;

  constructor(
    public badgeService: BadgeSocketService,
    private service: DashboardService,
    private serviceItem: ItemService,
    private serviceStore: StoreService,
    public dialogRef: MatDialogRef<OrderConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    public snackBar: MatSnackBar,
    public editOrder: EditOrder,
    private orderService: OrderService,) { }

  ngOnInit() {
    this.service.getTaskByRef(this.data.value.order_record.order_ref_no)
    .subscribe(res => {
      this.btn_update_stat = res.payload.task_records[0].level
    })
    this.getOrderedItems();
    this.getItems();
    this.type = this.tmp.usertype;
    console.log('hii',this.data)
    this.service.getTaskByRef(this.data.value.order_record.order_ref_no)
    .subscribe(res => {
      this.show_btns = true;
      this.task_data = res.payload.task_records[0]
      console.log('task_data', this.task_data)
      let uType = this.data.type;
      this.show_cancel = uType === 'cc' ? this.task_data.cc_cancel == 0 ? true : false : uType === 'cc' ? this.task_data.wh_cancel == 0 ? true : false : false
      
      this.cancel_txt = this.data.type === 'cc' ? this.task_data.cc_cancel == 0 ? '受注取消' : '元に戻す' : //元に戻す = Revert ; 受注取消 = Cancel
      this.data.type === 'wh' ? this.task_data.wh_cancel == 0 ? '受注取消' : '元に戻す' : 'None'     
    })
  }

  getData() {
    console.log(this.data.order_no);
    console.log(this.orderedItems.filter(entity => entity.order_id === this.data.order_no));
  }

  getOrderedItems() {
    this.service.getOrderedItems().subscribe(res => {
      if (res.success === true) {
        this.orderedItems = res.payload.ordered_items;
      } else {
        console.log("CAN NOT OBTAINED");
      }
    });
  }

  getItems() {
    this.serviceItem.getItems('', '0' , '50000').subscribe(res => {
      if (res.success === true) {
        this.items = res.payload.items;
      } else {
        console.log("CAN NOT OBTAINED");
      }
    });
  }

  getAll() {
    this.service.getAllOrders().subscribe(res => {
      this.allOrder = res.payload.order_data;
    });
    console.warn(this.allOrder);
  }

  exportFile(): void {

    this.serviceStore.getStore(this.data.value.order_record.store_id).subscribe(res => {
      if (res.success === true) {
        this.store = res.payload.store;

        let myCSV: CsvFormat[] = []
        let store_data = this.store
        let general_data = this.data.value.order_record
        let mydata: any[] = this.data.value.update_order.ordered_items
        let sum: number = 0
        let hoped_date = ""
        let hoped_format = ""
        if (formatDate(new Date(general_data.hoped_date), 'yyyy-MM-dd', 'en-US').toString() == "1970-01-01") {
          hoped_date = "0000"
        }
        else {
          hoped_date = formatDate(new Date(general_data.hoped_date), 'MMdd', 'en-US')
        }
        let hoped_time :any
        if (general_data.hoped_time == 3){
            hoped_time = '' 
        }else{
            hoped_time = general_data.hoped_time
        }
        hoped_format = hoped_date.toString() + hoped_time.toString()
        let woPrefCity = [];
        let woPref = this.store[0].address.split(this.store[0].pref.toString())
        woPrefCity = woPref[woPref.length - 1].toString().split(this.store[0].city.toString())
        let address_3 = ""
        let address_3_add = ""
        let address_3_pic = ""
        address_3_add = general_data.addressee + " 御中"
        let address_3_space = " ";
        address_3_pic = general_data.person_in_charge + " 様"
        if (general_data.addressee == "" || general_data.addressee == null) {
          address_3_add = ""; address_3_space = ""
        }
        if (general_data.person_in_charge == "" || general_data.person_in_charge == null) {
          address_3_pic = ""; address_3_space = ""
        }
        address_3 = address_3_add.toString() + address_3_space.toString() + address_3_pic.toString()

        for (let z = 0; z < mydata.length; z++) {
          sum += (mydata[z].qty * mydata[z].price)
        }
        for (let y = 0; y < mydata.length; y++) {
          let toCSV: any = {
            "注文ID": mydata[y].order_no,
            "注文日": formatDate(new Date(general_data.created_date), 'yyyy-MM-dd', 'en-US'),
            "注文時間": formatDate(new Date(general_data.created_date), 'HH:mm:ss', 'en-US'),
            "商品名": mydata[y].name,
            "商品番号": mydata[y].item_ref_no,
            "個数": mydata[y].qty,
            "単価": mydata[y].price,
            "注文者名字": '',
            "注文者名前": '',
            "メールアドレス": '',
            "注文者郵便番号1": '',
            "注文者郵便番号2": '',
            "注文者住所1:都道府県": '',
            "注文者住所2:都市区": '',
            "注文者住所3:町以降": '',
            "注文者電話番号1": '',
            "注文者電話番号2": '',
            "注文者電話番号3": '',
            "送付先名字": '',
            "送付先名前": store_data[0].store_name, //general_data.addressee + " " + general_data.person_in_charge ,
            "送付先郵便番号1": store_data[0].post_code,
            "送付先郵便番号2": '',
            "注文者住所1": store_data[0].pref,
            "注文者住所2": woPref[woPref.length - 1],
            "注文者住所3": address_3,
            "送付先電話番号1": store_data[0].tel,
            "送付先電話番号2": '',
            "送付先電話番号3": '',
            "決済方法": '銀行振込',
            "配送日指定": hoped_format,
            "配送方法": '',
            "時間帯指定": '',
            "品代合計(税込)": '',
            "送料(税込)": '',
            "代引き手数料(税込)": '',
            "合計金額(税込)": '',
            "調整額": '',
            "割引額(ポイント利用額)": '',
            "ポイント残高": '',
            "請求金額(税込)": '',
            "備考": general_data.person_in_charge,
            "別名1": general_data.dept_name,
            "荷扱い1": '',
            "メール通知": '',
            "入力機種": '',
            "通知メッセージ": '',
            "止め置き": '',
            "営業所コード": '',
            "熨斗フラグ": '',
            "ギフトフラグ": '',
            "倉庫備考": ''
          }
          myCSV.push(toCSV)
        }

        ExportUtility.downloadcsv(myCSV, "data_");
        //calling /order/esc 
        this.changeStatus("DL");


      } else {
        console.log("CAN NOT STOEgetStoreInfo OBTAINED");
      }
    });

  }

  save(body: any) {
    this.service.saveTask(body)
    .subscribe(ret => {
      if(this.isCancel){
        let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
        let order_array: any[] = []
        let toUpdate: any[] = []
        for (var $i = 0; $i < this.data.value.update_order.ordered_items.length; $i++) {
          order_array.push({
            item_no: this.data.value.update_order.ordered_items[$i].item_no,
            quantity: Number(this.data.value.update_order.ordered_items[$i].qty),
          });
        }
        for(let upd of this.data.value.update_order.ordered_items){
          let tmp = { 
            'item_id': upd.item_id,
            'item_no': upd.item_no,
            'qty': 0 - upd.qty
          }
          toUpdate.push(tmp)
        }
        console.log('MyOrders', this.data.value.update_order.ordered_items, toUpdate)
        let tmp2 = {
          'ref_num': this.data.value.order_record.order_ref_no,
          'order_items': order_array,
          'qty_update': toUpdate,
          'hoped_date': this.data.value.order_record.hoped_date,
          'hoped_time': this.data.value.order_record.hoped_time,
          'person_in_charge': this.data.value.order_record.person_in_charge,
          'note': this.data.value.order_record.note,
          'user_id': tmp.userid,
          'dept_name': this.data.value.order_record.dept_name,
          'urg_flg': this.data.value.order_record.urg_flg,
        }
        console.log('tmp2', tmp2)
        this.orderService.updateOrder(tmp2).subscribe(res => {
          // this.router.navigate(['../../../../main/dashboard/CC'])
        })
      }
      this.dialogRef.close(true)
    })
  }

  changeStatus(type: any) {

    let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))

    var body: any
    if (this.data.type === 'wh') {
      let body = {
        'head': 1,
        'reference': this.task_data.reference,
        'type': 1,
        'user_type': this.task_data.user_type,
        'user': tmp.userid,
        'level': 2,
        'user_id': tmp.userid,
        'cc_view': this.task_data.cc_view,
        'hd_view': this.task_data.hd_view,
        'wh_view': this.task_data.wh_view,
        'rr_view': this.task_data.rr_view,
        'cc_cancel': this.task_data.cc_cancel,
        'wh_cancel': this.task_data.wh_cancel
      }
      this.save(body)

    } else if (this.data.type === 'cc') {
      body = {
        'head': 3,
        'reference': this.data.value.order_record.order_ref_no,
        'type': 1,
        'user_type': this.data.value.order_record.esc_direction,
        'user': tmp.userid,
        'level': 4,
        'status': 0,
        'user_id': tmp.userid,
        'cc_view': 2,
        'hd_view': 2,
        'wh_view': 2,
        'rr_view': 2,
        'cc_cancel': this.task_data.cc_cancel,
        'wh_cancel': this.task_data.wh_cancel
      }

      if (this.task_data.level != 3) {
        body = {
          'head': this.task_data.head,
          'reference': this.task_data.reference,
          'type': 1,
          'user_type': this.task_data.user_type,
          'user': tmp.userid,
          'level': this.task_data.level,
          'status': 1,
          'user_id': tmp.userid,
          'cc_view': 2,
          'hd_view': this.task_data.hd_view,
          'wh_view': this.task_data.wh_view,
          'rr_view': this.task_data.rr_view,
          'cc_cancel': this.task_data.cc_cancel,
          'wh_cancel': this.task_data.wh_cancel
        }
        this.save(body)

      } else {
        this.save(body)
      }

    } else if (this.data.type === 'hd') {
      
      body = {
        'head': this.task_data.head,
        'reference': this.task_data.reference,
        'type': 1,
        'user_type': this.task_data.user_type,
        'user': tmp.userid,
        'level': this.task_data.level,
        'status': 1,
        'user_id': tmp.userid,
        'cc_view': this.task_data.cc_view,
        'hd_view': 2,
        'wh_view': this.task_data.wh_view,
        'rr_view': this.task_data.rr_view,
        'cc_cancel': this.task_data.cc_cancel,
        'wh_cancel': this.task_data.wh_cancel
      }
      this.save(body)

    }
  }

  justConfirm() {
    let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
    let body = {
      'head': this.task_data.head,
      'reference': this.task_data.reference,
      'type': 1,
      'user_type': this.task_data.user_type,
      'user': tmp.userid,
      'level': this.task_data.level,
      'status': 1,
      'user_id': tmp.userid,
      'cc_view': this.task_data.cc_view,
      'hd_view': this.task_data.hd_view,
      'wh_view': this.task_data.wh_view,
      'rr_view': 2,
      'cc_cancel': this.task_data.cc_cancel,
      'wh_cancel': this.task_data.wh_cancel
    }
    this.save(body)
  }

  updateOrder(){
    this.editOrder.edit_order = this.data.value.update_order
    this.router.navigate(['../../../../main/order/input/' + this.data.value.order_record.store_id]);
    this.dialogRef.close(true);
  }

  cancelOrder(){
    this.isCancel = false
    let stat = this.data.type === 'cc' ? this.task_data.cc_cancel == 0 && this.task_data.wh_cancel == 1 ? 0 : 1 :
    this.data.type === 'wh' ? this.task_data.cc_cancel == 1 && this.task_data.wh_cancel == 0 ? 0 : 1 : 1
    console.log(this.data.type, this.task_data.cc_cancel, this.task_data.wh_cancel, stat)
    let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
    let body = {
      'head': this.task_data.head,
      'reference': this.task_data.reference,
      'type': 1,
      'user_type': this.task_data.user_type,
      'user': tmp.userid,
      'level': this.task_data.level,
      'user_id': tmp.userid,
      'cc_view': stat == 0 ? 2 : this.task_data.cc_view,
      'hd_view': stat == 0 ? 2 : this.task_data.hd_view,
      'wh_view': stat == 0 ? 2 : this.task_data.wh_view,
      'rr_view': stat == 0 ? 2 : this.task_data.rr_view,
      'cc_cancel': this.data.type === 'cc' ? this.task_data.cc_cancel == 1 ? 0 : 1 : this.task_data.cc_cancel,
      'wh_cancel': this.data.type === 'wh' ? this.task_data.wh_cancel == 1 ? 0 : 1 : this.task_data.wh_cancel
    }

    let sBarMess = this.data.type === 'cc' ? this.task_data.cc_cancel == 0 ? 'CCのみ 取消済' : '取消済を元に戻す' :  //取消済を元に戻す = Revert Cancellation 
    this.data.type === 'wh' ? this.task_data.wh_cancel == 0 ? 'WHのみ 取消済' : '取消済を元に戻す' : 'None'

    this.isCancel = body.cc_cancel == 1 && body.wh_cancel == 1;
    sBarMess = this.isCancel ? 'WH CC 取消済' : sBarMess

    console.log('body', body)

    this.snackBar.open(sBarMess, '', {
      duration: 2000,
      panelClass: ['blue-snackbar']
    });
    this.save(body)
  }

}
