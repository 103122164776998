import { Component, OnInit, ViewChild, HostListener, NgModule, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Store } from '../../store/shared/store.model';
import { StoreService } from '../../store/shared/store.service';
import { MatDialog, MatSort, MatTableDataSource } from '@angular/material';
import { Item, Categories, Order } from '../shared/order.model';
import { ItemDetailDialogComponent } from './item-detail-dialog/item-detail-dialog.component';
import { OrderService } from '../shared/order.service';
import { OrderData, RelationalRefData } from '../shared/order.prov';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { formatDate } from '@angular/common';
import { EditOrder } from 'src/app/shared/edit-order.prov';
//import {default as _rollupMoment} from 'moment';
const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface ItemMoreDesc{
  hdate: string
  htime: string
  //addressee: string
  pic: string
  store_id: string
  note: string
  dept_name: string
}

export const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Component({
  selector: 'app-order-input-parent',
  templateUrl: './order-input.component.html',
  styleUrls: ['./order-input.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    StoreService, OrderService
  ],
})

export class OrderInputComponent implements OnInit, OnDestroy {

  acc_date = new FormControl();
  localUrl = "";

  private sort: MatSort;
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = this.sort;
  }

  public id: string;

  store: Store;
  stores: Store[] = []
  item: Item[] = [];
  order: Order[] = [];
  category: Categories[] = [];
  dataSource: MatTableDataSource<Item>;
  dataSource2: MatTableDataSource<Item>;
  displayedColumns = ['name'];
  isLoading = false;
  hasCart: boolean = false;
  hasData = false;
  search: string = '';
  displayBlank: any;
  rel: string = "";
  clear_update = true
  hasDate: boolean = false

  companySelect: any;
  btnDisable: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private storeService: StoreService,
    private orderService: OrderService,
    private dialog: MatDialog,
    private orderData: OrderData,
    private relData: RelationalRefData,
    private formBuilder: FormBuilder,
    public editOrder: EditOrder

  ) { }

  ngOnInit() {
    this.createForm()
    this.dataSource = new MatTableDataSource(this.item);
    this.dataSource2 = new MatTableDataSource(this.item);
    this.storeService.isDetailComponentShowing = true;
    this.id = this.route.snapshot.paramMap.get('id');
    this.getStore();
    this.rel = this.relData.ref_code;

    this.dataSource2.filterPredicate =
      (data: Item, filter: any) => data.cat.toString() == filter || filter === 'all';

    //reverting

    if (this.orderData.order) { 
      if (this.orderData.order.length > 0) {
        this.hasCart = true;
        this.order = this.orderData.order;
      }
    }

    if (this.relData.ref_code) {
      this.rel = this.relData.ref_code;
    }

    if(this.editOrder.edit_order !== null){
      let date_tmp = formatDate(new Date(this.editOrder.edit_order.order_record.hoped_date), 'yyyy-MM-dd', 'en-US')
      if(date_tmp !== '1970-01-01'){
        this.acc_date.setValue(date_tmp)
        this.userForm.get('htime').enable()
      }
    }

    this.btnDisable = true;
  }

  ngOnDestroy() {
    if(this.clear_update) this.editOrder.edit_order = null
  }

  getItemQty(item_no: string){
    let qty = ''

    if(this.order.length != 0){
      for(let tmp of this.order){
        if(tmp.item_no === item_no){
          qty = tmp.qty
        }
      }
    }else if(this.editOrder.edit_order !== null){
      for(let tmp of this.editOrder.edit_order.ordered_items){
        if(tmp.item_no === item_no){
          qty = tmp.qty
        }
      }
    }
    return qty
  }

  setOrderItems(){
    for(let tmp of this.editOrder.edit_order.ordered_items){
      for(let x = 0; x < this.item.length; x++){
        if(tmp.item_no === this.item[x].item_no){
          this.addList(this.item[x], tmp.qty)
        }
      }
    }
  }
  
  dateChange(event: any) {
    this.userForm.get('htime').enable()
    this.hasDate = true
  }
  //only partial
  mtier = 100
  availMess = '';

  isNotAvail(row){ 
    if(row.availability === 0){
      this.availMess = 'このアイテムは発注不可となっています。'
      return false
    } else if(this.mtier < row.tier ){
      this.availMess = 'このアイテムは本店舗では発注を受け付けておりません'
      return false
    } else {
      return true
    }
  }
  getStore() {
    if(this.editOrder.bulk_order != null) {
      console.log('getStore', this.editOrder.bulk_order)
      this.getItems();
      this.getCategories();
      this.storeService.getStores().subscribe(
        res =>{
          this.stores = res.payload.stores
        }
      )
      return
    }
    this.storeService.getStore(this.id).subscribe(res => {
      console.log('getStore', res)
      this.store = res.payload.store[0];
      this.mtier = this.store.tier
      console.log('getStore', this.store)
      console.warn(res);
      this.getChainName()
      this.getItems();
      this.getCategories();
    });
  }

  chainName: string
  getChainName() {
    this.orderService.getChainName(this.store.chain_no)
    .subscribe(res => {
      // console.log('getChainName', res)
      this.chainName = res.payload.chain[0].name
    })
  }

  getSuffix(key: number) {
    key.toString();
    return this.orderService.sufList[key].value;
  }

  //entire items
  getItems() {
    this.isLoading = true;
    this.hasData = false;

    this.orderService.getItems('', '0', '50000').subscribe(res => {
      if (res.success) {
        this.item = res.payload.items;
        if(this.editOrder.edit_order !== null)
          for(let itms of this.item)
            for(let edt of this.editOrder.edit_order.ordered_items)
              if(itms.item_id == edt.item_id)
                itms.booked_stock = (+itms.booked_stock) + (+edt.qty)
        if(this.editOrder.edit_order && this.order.length == 0) this.setOrderItems()
        // this.item = this.item.filter(x => x.tier <= this.store.tier)
        // console.log('filtered item', this.item)
        this.isLoading = false;
        if (this.item.length > 0) {
          this.hasData = true;
          for(let x = 0; x < this.item.length; x++){
            if(this.item[x].img == "" || this.item[x].img == " " || this.item[x].img == null ){
              this.item[x].img = ""
            }
          }
          console.log('asdfafasasf', this.item)
          this.dataSource.data = this.item;

          this.dataSource.filterPredicate = (data: Item, filters: string) => {
            const matchFilter = [];
            const filterArray = filters.split(',')
            const columns = [data.name]

            filterArray.forEach(filter => {
              const customFilter = [];
              columns.forEach(column => customFilter.push(column.toLowerCase().includes(filter)));
              matchFilter.push(customFilter.some(Boolean));
            })
            return matchFilter.every(Boolean);
          }
        }
      }
    });
  }

  tmpItem: Item[];

  applyFilterByCat(filterValue: any) {
    this.tmpItem = []
    if (filterValue === 'ALL') {
      this.tmpItem = this.item
    } else {
      for (let x = 0; x < this.item.length; x++) {
        if (this.item[x].cat === filterValue) {
          this.tmpItem.push(this.item[x])
        }
      }
    }
    this.dataSource.data = this.tmpItem
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  getCategories() {
    this.orderService.getCategories().subscribe(res => {
      this.category = res.payload.item_categories;
    });
  }

  getCatName(key) {
    var result = this.category.filter(x => x.cat_no === key);
    return result[0].cat_name
  }

  onView(id) {

    var obj = this.item;
    var arr = Object.keys(obj).map(function (key) { return obj[key] });
    Object.entries(obj).map(([key, value]) => ({ key, value }))

    var x = obj.filter(x => x.item_id === id);

    this.dialog.open(ItemDetailDialogComponent, {
      width: '70%',
      height: '600px',
      "data": {
        "id": x[0].item_id,
        "name": x[0].name,
        "item_no": x[0].item_no,
        "price": x[0].price,
        "cat": this.getCatName(x[0].cat),
        "booked_stock": x[0].booked_stock,
        "stock_type": x[0].stock_type,
        "tier": x[0].tier,
        "acc": x[0].acc,
        "spec1": x[0].spec1,
        "spec2": x[0].spec2,
        "spec3": x[0].spec3,
        "spec4": x[0].spec4,
        "spec5": x[0].spec5,
        "utencil_size": x[0].utencil_size,
        "pkg_size": x[0].pkg_size,
        "acc_pkg_size": x[0].acc_pkg_size,
        "min_lot": x[0].min_lot,
        "suffix": x[0].suffix,
        "ship_lot": x[0].ship_lot,
        "possibility": x[0].possibility,
        "mnfc": x[0].mnfc,
        "started_date": x[0].started_date,
        "exp_date": x[0].exp_date,
        "note": x[0].note,
        "img": x[0].img
      },
    });
  }

  values: any[] = [];
  err_msg: any[] = [];
  isDisabled: any[] = [];
  qty = '';

  onKey(event: any, item_id: string, barance,minlot) {
    var qty  = parseInt(event.target.value);

    this.values[item_id] = qty;
    var l = barance / minlot   
    if (event.target.value == '' || qty == 0) {
      this.isDisabled[item_id] = true;
      this.err_msg[item_id] = '';
    } 
    else if (qty > l) {
      this.isDisabled[item_id] = true;
      this.err_msg[item_id] = "在庫を上回っております。";
    }
    else if (qty > 0 && qty <= barance) {
      this.isDisabled[item_id] = false;
      this.err_msg[item_id] = '';
    }

    if((+event.target.value).toString() == 'NaN'){
      this.isDisabled[item_id] = true;
      this.err_msg[item_id] = ""
    }
  }

  mNumHandler(val: string){
    return (+val).toString() == 'NaN' ? 0 : val
  }

  addList(row, qty) {

    var chk = function (element) {
      return element.item_id === row.item_id;
    }

    if (this.order.some(chk)) {
      for (var i in this.order) {
        if (this.order[i].item_id == row.item_id) {
          this.order[i].qty = qty;
          break;
        }
      }
      //} 
    } else {
      this.order.push(row);
      row["qty"] = qty;
      this.hasCart = true;
    }
  }

  // Key changes
  userForm: FormGroup;
  // itemdesc: ItemMoreDesc
  createForm(){
    let mtime: string = this.editOrder.edit_order !== null ? this.editOrder.edit_order.order_record.hoped_time : '3'
    this.userForm = this.formBuilder.group({
      htime: [mtime !== '3' ? mtime : ''],
      pic: [this.editOrder.edit_order !== null ? this.editOrder.edit_order.order_record.person_in_charge : ''],
      note: [this.editOrder.edit_order !== null ? this.editOrder.edit_order.order_record.note : ''],
      dept_name: [this.editOrder.edit_order !== null ? this.editOrder.edit_order.order_record.dept_name : ''],
    })
    this.uFlag = this.editOrder.edit_order !== null ? this.editOrder.edit_order.order_record.urg_flg : false
    this.userForm.get('htime').disable()
  }

  uFlag = false;

  onFlagChange() {
    if (this.uFlag === true) {
      this.uFlag = true;
    } else {
      this.uFlag = false;
    }
  }

  toConfirm() {
    let accDate: any
    if(this.editOrder.edit_order !== null){
      if(this.editOrder.edit_order.order_record.hoped_date.length != 0) this.hasDate = true
    }
    if(this.hasDate) accDate = formatDate(new Date(this.acc_date.value), 'yyyy-MM-dd', 'en-US')
    else accDate = '1970-01-01'
    let itemDesc = this.userForm.getRawValue()

    this.orderData.order = this.order;
    this.orderData.flag = this.uFlag;
    this.relData.ref_code = this.rel;
    this.orderData.order_desc = {
      hdate: accDate,
      htime: itemDesc.htime,
      //addressee: itemDesc.addressee,
      pic: itemDesc.pic,
      //store_id: itemDesc.store_id,
      dept_name: itemDesc.dept_name,
      note: itemDesc.note
    }
    if(this.editOrder.bulk_order == null){
      this.orderData.store = this.store;
      this.orderData.store.chain_name = this.chainName;
    }
    this.orderData.stores = this.stores
    this.clear_update = false
    this.router.navigate(['/main/order/confirm/' + this.id]);
  }

  //discard item
  discardItem(item_id: number, name: string, row: number) {
    if (window.confirm("【" + name + "】を一覧から削除します。よろしいですか？")) {
      var target = item_id;
      var filteredArray = this.order.filter(function (element, index, array) {
        return (element.item_id && element.item_id !== target);
      });

      this.order = filteredArray;
      this.values[item_id] = '';
      alert("【" + name + "】を一覧から削除しました。");
      
    }

    if (this.order.length == 0) {
      this.hasCart = false;
    }
  }
}