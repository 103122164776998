import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from '../shared/dashboard.service';
import { MatDialog } from '@angular/material';
import { InqConfirmComponent } from '../shared/inq-confirm/inq-confirm.component';
import { OrderConfirmComponent } from '../shared/order-confirm/order-confirm.component';
import { Inquiries, allOrders, OrderRecords } from '../shared/ordered-item.model';
import { ManualList } from '../../manual-management/shared/manual-list-interface';
import { ManualService } from '../../manual-management/shared/manual.service';
import { CollConfirmComponent } from '../shared/coll-confirm/coll-confirm.component';
import { MaintenanceOptionComponent } from '../shared/maintenance-option/maintenance-option.component';
import { BadgeSocketService } from 'src/app/shared/badge-socket.service';

@Component({
  selector: 'app-db-cc',
  templateUrl: './db-cc.component.html',
  styleUrls: ['./db-cc.component.scss'],
  providers:[BadgeSocketService]
})

export class DbCcComponent implements OnInit {
  inquiries: Inquiries[] = [];
  orders: OrderRecords[] = [];
  allOrders: allOrders[] = [];

  itemStr: string[] = []

  manuals: ManualList[] = [];
  task_records: any;
  isActive: '';
  tab: string

  iCnt: number;
  hasInqData = false;
  hasOrderData = false;
  badges: any[] = []
  badge_inq :any; badge_ord :any; badge_coll :any; badge_main :any;

  constructor(
    public badgeService: BadgeSocketService,
    private service: DashboardService,
    public dialog: MatDialog,
    public manualService: ManualService) { 
      
      this.badgeService.listenBadgeUpdate('cc').subscribe( res => {
        console.log('got a message from cc')
        this.getBadgeCnt()
      })
      //this.tchange('inq')

  }

  ngOnInit() {
    this.getManuals();
    this.getBadgeCnt();
  }

  getBadgeCnt(){
    this.service.getDashboardCnt('cc').subscribe(res => {
      this.tchange('inq');
      this.badges = res.result
    })
  }

  getManuals() {
    this.manualService.getManualList().subscribe(res => {
      if (res.success) {
        this.manuals = res.payload.files
        console.log('Manual', this.manuals);
      }
    })
  }

  changeTab(event) {
    this.tchange(event.tab.textLabel);
  }

  newBadge_counter(task_records_length, info){
  // inq , ord , main , coll
  
    if (info == "inq"){
      this.badge_inq = this.task_records.length
      console.log( this.badge_inq )
    }
    if (info == "ord"){
      this.badge_ord = this.task_records.length
      console.log( this.badge_ord )
    }
    if (info == "main"){
      this.badge_main = this.task_records.length
      console.log( this.badge_main )
    }
    if (info == "coll"){
      this.badge_coll = this.task_records.length
      console.log( this.badge_coll )
    }
  }

  tchange(info) {
    
    this.tab = info
    this.service.getTaskRecords('cc_' + info).subscribe(res => {
      console.log('tchange', res)
      if (res.success === true) {
        this.isActive = info;

        this.task_records = res.payload.task_records;

        //this.newBadge_counter(this.task_records.length, info)

        if (info === 'ord') {
          for (let tmp of res.payload.task_records) {
            let str: string = ''
            for (let y = 0; y < tmp.ordered_items.length; y++) {
              str += tmp.ordered_items[y].name + '×' + tmp.ordered_items[y].qty + (y < (tmp.ordered_items.length - 1) ? ', ' : '')
            }
            this.itemStr.push(str)
          }
        }
      } else {  
        this.hasInqData = true;
      }
    })
  }

  maintenanceOpt(t) {
    const dialog = this.dialog.open(MaintenanceOptionComponent, {
      width: '980px',
      data: {
        'userType': 'cc',
        'mData': t
      }

    })
    dialog.afterClosed().subscribe(res => {
      this.tchange(this.tab)
    })
  }

  //Confirm Modal
  onInqConfirm(rowData: Inquiries) {
    const dialogRef = this.dialog.open(InqConfirmComponent,
      {
        width: '980px',
        data: {
          'type': 'cc',
          'mdata': rowData
        }
      }
    )
    dialogRef.afterClosed().subscribe(res => {
      if (res) this.tchange(this.tab);
    })
  }

  onOrderConfirm(rowData: OrderRecords, o: any) {
    console.log('onOrderConfirm', o)
    const dialogRef = this.dialog.open(OrderConfirmComponent,
      {
        width: '980px',
        data: {
          'type': 'cc',
          'value': {
            'order_record': rowData,
            'update_order': o
          }
        }
      }

    )
    dialogRef.afterClosed().subscribe(res => {
      if (res) this.tchange(this.tab);
    })
  }

  //Confirm Modal
  onCollConfirm(rowData: Inquiries) {
    const dialogRef = this.dialog.open(CollConfirmComponent,
      {
        width: '980px',
        data: {
          'type': 'cc',
          'mdata': rowData
        }
      }
    )
    dialogRef.afterClosed().subscribe(res => {
      if (res) this.tchange(this.tab);
    })
  }
}
