import { itemsUrl, ccInquiry , filterByRefTaskUrl, hdDelete, hdInquiry, whInquiry, hdOrder, ccDelete, whDelete, whOrder, ccOrder, saveTaskUrl, multiSave, getTaskRecords, venderUrl, fileUploadUrl, updateDetail, badgeCoutUrl, collectionUrl } from './../../shared/data.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEvent, HttpEventType ,HttpParams, HttpResponse} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { maintenanceTaskrecordsUrl, DataService,getInqUrl,getOrderRecordsUrl,inquiryUrl,getOrderedItemsUrl,allOrdersUrl,getOrdersUrl,setOrderUrl,shipRecord,itemUrl } from '../../shared/data.service';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  enableLog: boolean;
  tmp: any = JSON.parse(sessionStorage.getItem('currentUser'));

  public log(message: any) { if (this.enableLog) console.log(message); }

  constructor(
  	private dataService: DataService,public http: HttpClient) { 
  }

  getInquiries(){
    return this.dataService.get(getInqUrl, null);
  }

  //for table display
  getOrders(){
    return this.dataService.get(getOrdersUrl, null);
  }

  //for conbine CSV
  getOrderRecords(){
    return this.dataService.get(getOrderRecordsUrl, null);
  }

  //for conbine CSV
  getOrderedItems(){
    return this.dataService.get(getOrderedItemsUrl, null);
  }

  getItemById(id){
    var iUrl = itemUrl + '/' +id;
    return this.dataService.get(iUrl,null);
  }

  getItems(){
    return this.dataService.get(itemsUrl, null)
  }

  getVenders(){
    return this.dataService.get(venderUrl, null)
  }
  
  getAllOrders() {
    return this.dataService.get(allOrdersUrl,null);
  }

  updateEsc(body:any) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let escurl = setOrderUrl + 'esc/' + body.order_ref_no;

    body = new HttpParams()
      .set('esc_status', body.esc_status)
      .set('esc_direction', body.esc_direction)
      .set('updated_by', this.tmp.userid)

      return this.http.put(escurl, body.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
  }

  saveCSV(str: string){
    return this.dataService.post(shipRecord, JSON.parse(str))
  }

  maintenanceTaskrecords(body, id){
    return this.dataService.put(maintenanceTaskrecordsUrl + id, body);
  }

  getCcInquiry(){
    return this.dataService.get(ccInquiry, null);
  }

  getCcOrder(){
    return this.dataService.get(ccOrder, null);
  }

  getHdInquiry(){
    return this.dataService.get(hdInquiry, null);
  }
 
  getWhInquiry(){
    return this.dataService.get(whInquiry, null);
  }

  getWhOrder(){
    return this.dataService.get(whOrder, null);
  }

  getTaskByRef(id: any){
    return this.dataService.get(filterByRefTaskUrl, id);
  }

  hdDelete(body: any){
    return this.dataService.deleteTask(hdDelete, body)
  }

  ccDelete(body: any){
    return this.dataService.deleteTask(ccDelete, body)
  }

  whDelete(body: any){
    return this.dataService.deleteTask(whDelete, body)
  }

  saveTask(body: any){
    return this.dataService.saveTask(saveTaskUrl, body)
  }
  
  saveMultiCSV(body: any){
    return this.dataService.saveCSV(multiSave, body)
  }

  getTaskRecords(task_inf : any){
    return this.dataService.get(getTaskRecords, task_inf);
  }

  getDashboardCnt(task_inf : any){
    return this.dataService.get(badgeCoutUrl, task_inf);
  }

  maintenanceGetimages(body){
    return this.dataService.post(maintenanceTaskrecordsUrl + 'get-images', body);
  }

  fileUpload(body: any, files: any){

    return this.dataService.fileUpload(fileUploadUrl, body, files)
  }

  updateDetail(body: any){
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let params = new HttpParams()
      .set('added_by', body.added_by)
      .set('details', body.details)
      .set('ref_no', body.ref_no);

    return this.http.post(updateDetail, params.toString(), { headers, observe: 'response' })
      .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
      .catch((err: any) => Observable.of(false));
  }

}
