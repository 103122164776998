import { StoreOptComponent } from './../store-opt/store-opt.component';
import { Store_list } from './../shared/store.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatDialog, MatTableDataSource } from '@angular/material';
import { StoreService } from '../shared/store.service';
import { StoreViewComponent } from '../store-view/store-view.component';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { Chain_list } from '../../chain-management/shared/chain.model';
import { ChainService } from '../../chain-management/shared/chain.service';

@Component({
  selector: 'app-store-list',
  templateUrl: './store-list.component.html',
  styleUrls: ['./store-list.component.scss']
})
export class StoreListComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;

  stores: Store_list[] = [];
  dataSource: MatTableDataSource<Store_list>;
  displayedColumns = ['id', 'store_name', 'chain_no', 'address', 'viewAction', 'editAction', 'deleteAction'];
  pbVisible: boolean = false;
  msearch: string = ''
  offset: number = 0
  limit: string = '20'
  total: number = 0
  search_loading: boolean = false

  constructor(
    public service: StoreService
    , public dialog: MatDialog
    , public chainService: ChainService
  ) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.stores);
    this.getStores(this.msearch);
    this.getChains()
    this.dataSource.sort = this.sort;
  }

  chains: Chain_list[] = []
  getChains(){
    this.chainService.getUserList()
    .subscribe(res => {
      this.pbVisible = true
      console.log('GET CHAINSSSS ', res)
      this.pbVisible = true
      this.chains = res.payload.chains
      
    })
  }

  getStores(tosearch: string){
    this.search_loading = true
    this.msearch = tosearch.trimLeft()
    this.msearch = this.msearch.trimRight()
    this.service.getStoreList(this.msearch, '' + this.offset, this.limit)
    .subscribe(res => {
      this.pbVisible = true
      this.search_loading = false
      if(res.success){
        this.stores = res.payload.stores
        this.total = res.payload.total.total
        // if(this.stores.length !== 0){
          this.dataSource.data = this.stores
          this.dataSource.filterPredicate = (data: Store_list, filters: string) =>{
            const matchFilter = [];
            const filterArray = filters.split(' ')
            const columns = [data.store_name, data.chain_no.toString(), data.address]
            filterArray.forEach(filter =>{
              const customFilter = [];
              columns.forEach(column => customFilter.push(column.toLowerCase().includes(filter)));
              matchFilter.push(customFilter.some(Boolean));
            })
            return matchFilter.every(Boolean);
          }
        // }
      }
    })
  }

  limitChange(){
    // let o = offset, l = limit, t = total, N = new offset
    // N = o - ( ( o + l ) - t )
    this.offset = Math.max(0, this.offset - Math.max(0, (this.offset + (+this.limit)) - this.total))
    this.getStores(this.msearch)
  }

  actionPage(action: number){
    if(action == 1){ // Fist Page
      this.offset = 0
      this.getStores(this.msearch)
    }else if(action == 2){ // Previous Page
      let tmp = this.offset - (+this.limit)
      if(this.offset == 0) return
      this.offset = tmp <= 0 ? 0 : tmp
      this.getStores(this.msearch)
    }else if(action == 3){ // Next Page
      let tmp = this.offset + (+this.limit)
      if(tmp >= this.total) return
      this.offset = tmp
      this.getStores(this.msearch)
    }else if(action == 4){ // Last Page
      let ttl = this.total - (+this.limit)
      this.offset = ttl <= 0 ? 0 : ttl
      this.getStores(this.msearch)
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openDiaog(rowData: Store_list){
    this.dialog.open(StoreViewComponent, { width: '980px', height:'550px',
    data: rowData })
  }

  openOptionDialog(opt: string, val: Store_list){
    const dialogRef = this.dialog.open(StoreOptComponent, { width: '980px', height:'550px',
      data: {
          option: opt, desc: val, chains: this.chains
        } ,
      disableClose: true
    })
    dialogRef.afterClosed().subscribe(res => {
      if(res) this.getStores(this.msearch)
    })
  }
  openOptionDialogAdd(opt: string){
    let userTmp: Store_list[] = []
    const dialogRef = this.dialog.open(StoreOptComponent, { width: '980px', height:'550px',
      data: {
        option: opt, desc: userTmp, chains: this.chains
      } ,
    disableClose: true
    })
    dialogRef.afterClosed().subscribe(res => {
      if(res) this.getStores(this.msearch)
    })
  }

  onDelete(val: Store_list){
    
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: {
        name: val.store_name
      }
    })
    dialogRef.afterClosed().subscribe(res => {
      if(res){
        let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
        let body = {
          'id': val.id,
          'store_no': val.store_no,
          'deleted_by': tmp.userid
        }
        this.service.storeOpt(body, 'delete')
        .subscribe(res => {
          this.getStores(this.msearch)
        })
      }
    })
  }

}
