import { Injectable } from '@angular/core';
import { DataService, storeUrl, getStoresUrl, storeOptUrl, delStoretUrl, fetchStores } from '../../shared/data.service';
import { HttpHeaders, HttpParams, HttpClient, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(private dataService: DataService, public http: HttpClient) { }

  getStoreList(msearch: string, offset: string, limit: string){
    // return this.dataService.get(getStoresUrl,null);
    //return this.dataService.get('https://api.myjson.com/bins/9qhm8', null);
    // return this.dataService.get(coopList, null);

    return this.dataService.get(fetchStores + '?mseach=' + msearch + '&offset=' + offset + '&limit=' + limit, null);
  }

  storeOpt(body: any, opt: string){
    if(opt === 'delete')
      return this.dataService.storeOpt(delStoretUrl, body, opt);
    else
      return this.dataService.storeOpt(storeOptUrl, body, opt);
  }
}
