import { CollectionData } from './../../collection/shared/collection.prov';
import { MaintenanceData } from './../../maintenance/shared/maintenance.prov';
import { OrderData, RelationalRefData } from './../../order/shared/order.prov';

import { Component, OnInit, ViewChild } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { MatDialog, MatSort, MatTableDataSource } from '@angular/material';
import { ConfirmStoreDialogComponent } from './confirm-store-dialog/confirm-store-dialog.component';

import { Store } from '../shared/store.model';
import { StoreService } from '../shared/store.service';
import { ResponseContentType } from '@angular/http';
import { InquiryData } from 'src/app/inquiry/shared/inquiry.prov';
import { Papa } from 'ngx-papaparse';
import { EditOrder } from 'src/app/shared/edit-order.prov';
import { BadgeSocketService } from 'src/app/shared/badge-socket.service';

@Component({
  selector: 'app-store-index',
  templateUrl: './store-index.component.html',
  styleUrls: ['./store-index.component.scss'],
  providers: [Papa, BadgeSocketService]
})

export class StoreIndexComponent implements OnInit {
  hTitle: string = '';
  svg: string = '';

  @ViewChild(MatSort) sort: MatSort;
  /*
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = this.sort;
  }

  @ViewChild(MatSort) sort: MatSort;
  */

  stores: Store[] = [];
  dataSource: MatTableDataSource<Store>;

  displayedColumns = ['store_no','chain_name','store_name','tel','address', 'viewAction'];
  
  search: string = '';
  isLoading = false;
  hasData: boolean;
  searchWord = '';
  hasResult:boolean = false;
  pbVisible: boolean = false;

  msearch: string = ''
  offset: number = 0
  limit: string = '10'
  total: number = 0
  search_loading: boolean = false
  show_btn_bulk_order: boolean = false
  userDesc = JSON.parse(sessionStorage.getItem('currentUser'))

  constructor(
    public badgeService: BadgeSocketService,
    private _location: Location,
    private activeRoute: ActivatedRoute,
    private service: StoreService,
    public dialog: MatDialog,
    private papa: Papa,
    public editOrder: EditOrder,
    private router: Router,
    private orderData: OrderData,
    private maintenanceData: MaintenanceData,
    private inquiryData: InquiryData,
    private collectionData: CollectionData,
    private relationData: RelationalRefData) { }


  ngOnInit() {
    // this.getStores(this.msearch);
    //this.editOrder.bulk_order = null
    //this.editOrder.csv_order = null
    //this.editOrder.csv_size = null
    this.clearSharedData()
    this.dataSource = new MatTableDataSource(this.stores);
    this.dataSource.sort = this.sort; 
    this.judgeTitle();
  }

  clearSharedData(){
    this.editOrder.edit_order = null
    this.editOrder.bulk_order = null
    this.editOrder.csv_order = null
    this.editOrder.csv_size = 0

    this.orderData.order = null
    this.orderData.flag = null
    this.orderData.store = null
    this.orderData.ref_code = ''
    this.orderData.order_desc = null

    this.relationData.ref_code =  ''
    this.relationData.inq_ref = ''
    this.relationData.order_ref = ''
    this.relationData.mainte_ref = ''
    this.relationData.coll_ref = ''
    this.relationData.updID = null

    this.inquiryData.store = null
    this.inquiryData.inquiry = null
    this.inquiryData.ref_code = ''

    this.maintenanceData.mainFile = null
    this.maintenanceData.data = null

    this.collectionData.store = null
    this.collectionData.store_id = null
    this.collectionData.remarks = null
    this.collectionData.address = null
    this.collectionData.shop_incharge = null
    this.collectionData.shop_date_hopes = null
    this.collectionData.urg_flg = null
    this.collectionData.ref_code = ''
    this.collectionData.phone_number = ''
  }   

  getParent(){
    var str = this._location.path();
    var path = str.split('/');
    return path[2];
  }

  judgeTitle() {
    this.pbVisible = true
    switch(this.getParent()){
      case 'order':
        this.hTitle = this.userDesc.usertype == 0 ? '一括発注機能' : "受注依頼入力";
        this.svg = this.getParent();
        break;
      case 'inquiry':
        this.hTitle = "問い合わせ入力";
        this.svg = this.getParent();
        break;
      case 'collection':
        this.hTitle = "回収依頼入力";
        this.svg = this.getParent();
        break;
      case 'maintenance':
        this.hTitle = "メンテナンス依頼入力";
        this.svg = this.getParent();
    }
  }

  actionPage(action: number){
    if(action == 1){ // Fist Page
      this.offset = 0
      this.getStores(this.msearch)
    }else if(action == 2){ // Previous Page
      let tmp = this.offset - (+this.limit)
      if(this.offset == 0) return
      this.offset = tmp <= 0 ? 0 : tmp
      this.getStores(this.msearch)
    }else if(action == 3){ // Next Page
      let tmp = this.offset + (+this.limit)
      if(tmp >= this.total) return
      this.offset = tmp
      this.getStores(this.msearch)
    }else if(action == 4){ // Last Page
      let ttl = this.total - (+this.limit)
      this.offset = ttl <= 0 ? 0 : ttl
      this.getStores(this.msearch)
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  limitChange(){ 
    this.offset = Math.max(0, this.offset - Math.max(0, (this.offset + (+this.limit)) - this.total))
    this.getStores(this.msearch)
  }

  getStores(tosearch: string){
    this.search_loading = true
    this.msearch = tosearch.trimLeft()
    this.msearch = this.msearch.trimRight()
    this.service.getStoreList(this.msearch, '' + this.offset, this.limit)
    .subscribe(res => { 
      this.search_loading = false
      if(res.success){
        this.stores = res.payload.stores
        console.log('getstores',this.stores)
        this.total = res.payload.total.total
        if(this.stores.length == 0){
          this.hasResult = false;
        } else {
          this.hasResult = true;
        }
          this.dataSource.data = this.stores
          this.dataSource.filterPredicate = (data: Store, filters: string) =>{
            const matchFilter = [];
            const filterArray = filters.split(' ')
            const columns = [data.store_name, data.chain_no.toString(), data.address]
            filterArray.forEach(filter =>{
              const customFilter = [];
              columns.forEach(column => customFilter.push(column.toLowerCase().includes(filter)));
              matchFilter.push(customFilter.some(Boolean));
            })
            return matchFilter.every(Boolean);
          }
        }
      // }
    })
  }
 
  openDialog(id){

    var obj =　this.stores;
    var arr = Object.keys(obj).map(function (key) {return obj[key]});
    Object.entries(obj).map(([key, value]) => ({key, value}))

      //var x = obj.filter(x => x.store_no === id);
      var x = obj.filter(x => x.id === id);
      console.log(x);

    this.dialog.open(ConfirmStoreDialogComponent,{
      width: '980px',
      "data" : {
        //"store_no" : x[0].store_no,     
        "store_no" : x[0].id,
        "chain_name" : x[0].chain_name,
        "store_name" : x[0].store_name,
        "tel" :  x[0].tel,
        "address" : x[0].address,
        "parent" : this.getParent(),
      }
    });
  }

  onSelectFile(event: any){
    let options = {
      complete: (results, file) => {
        if(results.data.length <= 1){
          alert('Invalid CSV')
          return
        }
        this.service.getStores().subscribe(
          res => {
            let tmpStores = res.payload.stores
            this.editOrder.csv_order = file.name
            this.editOrder.bulk_order = results.data
            this.editOrder.csv_size = file.size
            for(let x = 1; x < results.data.length; x++){
              let data = results.data[x]
              let isExist = false
              for(let stores of tmpStores){
                if(stores.chain_store_id === data[1]) isExist = true
              }
              if(!isExist){
                alert( data[1] + " 店舗IDは存在しています。CSVファイルを確認してください。")
                return
              }
            }
            this.router.navigate(['../../../../main/order/input/bulk-order']);
          }
        )
      }
    }
    this.papa.parse(event.target.files[0], options);
  }
}