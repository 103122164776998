import { WhAuthGuard } from './dashboard/auth-guards/wh-auth.guard';
import { DbGgComponent } from './dashboard/db-gg/db-gg.component';
import { HttpHeaders } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { MainComponent } from './main/main.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DbCcComponent } from './dashboard/db-cc/db-cc.component';
import { DbHdComponent } from './dashboard/db-hd/db-hd.component';
import { DbWhComponent } from './dashboard/db-wh/db-wh.component';
import { DbRrComponent } from './dashboard/db-rr/db-rr.component';
import { DbVrComponent } from './dashboard/db-vr/db-vr.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { AdminUsersComponent } from './admin-users/admin-users.component';
import { SignInComponent } from './admin-users/sign-in/sign-in.component';
// Providers
import { AuthGuard } from './admin-users/shared/guards/auth.guard';
import { AuthService } from './admin-users/shared/auth.service';

//regarding reset password
import { ResetPasswordComponent } from './admin-users/reset-password/reset-password.component';
import { SentEmailComponent } from './admin-users/reset-password/sent-email/sent-email.component';
import { SetNewpasswordComponent } from './admin-users/reset-password/edit/edit.component';
import { ResetCompleteComponent } from './admin-users/reset-password/complete/complete.component';
import { VerifyComponent } from './admin-users/reset-password/verify/verify.component';
//ordering
import { StoreIndexComponent } from './store/store-index/store-index.component';
import { OrderComponent } from './order/order.component';
import { OrderInputComponent } from './order/order-input/order-input.component';
import { OrderConfirmComponent } from './order/order-confirm/order-confirm.component';
import { OrderCompleteComponent } from './order/order-complete/order-complete.component';
//import { BeforeunloadGuard } from './order/shared/order.guard';

//inquiry
import { InquiryComponent } from './inquiry/inquiry.component';
import { InquiryInputComponent } from './inquiry/inquiry-input/inquiry-input.component';
import { InquiryConfirmComponent } from './inquiry/inquiry-confirm/inquiry-confirm.component';
import { InquiryCompleteComponent } from './inquiry/inquiry-complete/inquiry-complete.component';

//collection
import { CollectionComponent } from './collection/collection.component';
import { CollectionInputComponent } from './collection/collection-input/collection-input.component';
import { CollectionConfirmComponent } from './collection/collection-confirm/collection-confirm.component';
import { CollectionCompleteComponent } from './collection/collection-complete/collection-complete.component';
//maintenance
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { MaintenanceInputComponent } from './maintenance/maintenance-input/maintenance-input.component';
import { MaintenanceConfirmComponent } from './maintenance/maintenance-confirm/maintenance-confirm.component';
import { MaintenanceCompleteComponent } from './maintenance/maintenance-complete/maintenance-complete.component';
/** Shared Components **/
import { BlankListComponent } from './shared/blank-list/blank-list.component';
import { ImageToolsComponent, ImageToolsComponentDialog } from './shared/image-tools/image-tools.component';
import { ImageToolsDetailComponent } from './shared/image-tools/image-tools-detail/image-tools-detail.component';
import { ImageToolsDetailMultipleComponent } from './shared/image-tools/image-tools-detail-multiple/image-tools-detail-multiple.component';
import { PaginatorComponent } from './shared/paginator/paginator.component';
import { ProgressComponent } from './shared/progress/progress.component';
import { ToolbarComponent } from './shared/toolbar/toolbar.component';

//vender
import { VenderManagementComponent } from './vender-management/vender-management.component';
import { VenderListComponent } from './vender-management/vender-list/vender-list.component';


/**  Admin and Regular user's components **/

/**  ONLY for Admin user's components**/
import { UserManagementComponent } from './user-management/user-management.component'; //temporary
import { UserListComponent } from './user-management/user-list/user-list.component';

import { ItemManagementComponent } from './item-management/item-management.component';
import { ItemListComponent } from './item-management/item-list/item-list.component';

import { ItemCategManagementComponent } from './item-categ-management/item-categ-management.component';
import { ItemCategListComponent } from './item-categ-management/item-categ-list/item-categ-list.component';

import { StoreListComponent } from './store-management/store-list/store-list.component';
import { StoreManagementComponent } from './store-management/store-management.component';

import { ChainListComponent } from './chain-management/chain-list/chain-list.component';
import { ChainManagementComponent } from './chain-management/chain-management.component';

import { UsertypeAuthGuard } from './admin-users/shared/guards/usertype.guard';
import { AllInboundComponent } from './all-inbound/all-inbound.component';
import { InboundListComponent } from './all-inbound/inbound-list/inbound-list.component';

import { ManualManagementComponent } from './manual-management/manual-management.component'; //temporary
import { DownloadTableCsvComponent } from './download-table-csv/download-table-csv.component';
import { WorkflowSettingComponent } from './workflow_setting/workflow_setting.component'; //temporary
import { WaitingTaskComponent } from './waiting_task/waiting_task.component'; //temporary
import { RedirectDashboardComponent } from './dashboard/redirect-dashboard/redirect-dashboard.component';
import { CcAuthGuard } from './dashboard/auth-guards/cc-auth.guard';
import { HdAuthGuard } from './dashboard/auth-guards/hd-auth.guard';
import { GgAuthGuard } from './dashboard/auth-guards/gg-auth.guard';
import { RrAuthGuard } from './dashboard/auth-guards/rr-auth.guard';
import { VrAuthGuard } from './dashboard/auth-guards/vr-auth.guard';

const routes: Routes = [
  { path: '', redirectTo: '/signin', pathMatch: 'full'},
  { path: 'signin', component: SignInComponent },

  //Regarding Reset Password
  { path: 'reset-password', component:ResetPasswordComponent },
  { path: 'reset-password/sent-email', component:SentEmailComponent },
  { path: 'reset-password/edit', component:SetNewpasswordComponent },
  { path: 'reset-password/complete', component:ResetCompleteComponent },
  { path: 'reset/:token', component:VerifyComponent },

  { path: 'main',  component: MainComponent, canActivateChild: [ AuthGuard ],
    children: [
      { path: 'dashboard', component: DashboardComponent ,canActivateChild:[UsertypeAuthGuard],
      children: [
        { path: '', redirectTo: 'redirect', pathMatch: 'full' },
        { path: 'CC',component: DbCcComponent, canActivate: [CcAuthGuard] },
        { path: 'HD',component: DbHdComponent, canActivate: [HdAuthGuard] },
        { path: 'WH',component: DbWhComponent, canActivate: [WhAuthGuard] },
        { path: 'GG',component: DbGgComponent, canActivate: [GgAuthGuard] },
        { path: 'RR',component: DbRrComponent, canActivate: [RrAuthGuard] },
        { path: 'VR',component: DbVrComponent, canActivate: [VrAuthGuard] },
        { path: 'redirect', component: RedirectDashboardComponent },
        { path: '**', component: PageNotFoundComponent }
        ]},
      /** **/
      { path: 'user-management', component: UserManagementComponent,canActivate: [UsertypeAuthGuard],
        children: [
          { path: '', redirectTo: 'user-list', pathMatch: 'full' },
          { path: 'user-list', component: UserListComponent },
          { path: '**', component: PageNotFoundComponent }
        ]
      },
      /** ORDER **/
      { path: 'order', component:  OrderComponent, canActivate:[UsertypeAuthGuard],
        children: [
          { path: '', redirectTo: 'select-store', pathMatch: 'full' },
          { path: 'select-store', component: StoreIndexComponent },
          { path: 'input/:id', component: OrderInputComponent},
          { path: 'confirm/:id', component: OrderConfirmComponent},
          { path: 'complete/:id', component: OrderCompleteComponent },
          { path: '**', component: PageNotFoundComponent }
        ]
      },
      /** Inquiry **/
      { path: 'inquiry', component:  InquiryComponent,canActivate: [UsertypeAuthGuard],
        children: [
          { path: '', redirectTo: 'select-store', pathMatch: 'full' },
          { path: 'select-store', component: StoreIndexComponent, },
          { path: 'input/:id', component: InquiryInputComponent　},
          { path: 'confirm/:id', component: InquiryConfirmComponent　},
          { path: 'complete/:id', component: InquiryCompleteComponent },
          { path: '**', component: PageNotFoundComponent }
        ]
      },
      /** Collection **/
      { path: 'collection', component:  CollectionComponent,canActivate: [UsertypeAuthGuard],
        children: [
          { path: '', redirectTo: 'select-store', pathMatch: 'full' },
          { path: 'select-store', component: StoreIndexComponent, },
          { path: 'input/:id', component: CollectionInputComponent　},
          { path: 'confirm/:id', component: CollectionConfirmComponent},
          { path: 'complete/:id', component: CollectionCompleteComponent },
          { path: '**', component: PageNotFoundComponent }
        ]
      },
      /** Maintenance **/
      { path: 'maintenance', component:  MaintenanceComponent,canActivate: [UsertypeAuthGuard],
        children: [
          { path: '', redirectTo: 'select-store', pathMatch: 'full' },
          { path: 'select-store', component: StoreIndexComponent, },
          { path: 'input/:id', component: MaintenanceInputComponent　},
          { path: 'confirm/:id', component: MaintenanceConfirmComponent　},
          { path: 'complete/:id', component: MaintenanceCompleteComponent },
          { path: '**', component: PageNotFoundComponent }
        ]
      },
      
      /** Workflow Setting **/
      { path: 'workflow_setting', component:  WorkflowSettingComponent,canActivate: [UsertypeAuthGuard],
      },

      /** Waiting Task **/
      { path: 'waiting_task', component:  WaitingTaskComponent,canActivate: [UsertypeAuthGuard],
      },

      /** CHAIN Management **/
      { path: 'chain-management', component: ChainManagementComponent, canActivate: [UsertypeAuthGuard],
        children: [
          { path: '', redirectTo: 'chain-list', pathMatch: 'full' },
          { path: 'chain-list', component: ChainListComponent },
          { path: '**', component: PageNotFoundComponent }
        ] 
      },
      /** Store Management**/
      { path: 'store-management', component: StoreManagementComponent, canActivate: [UsertypeAuthGuard],
        children: [
          { path: '', redirectTo: 'store-list', pathMatch: 'full' },
          { path: 'store-list', component: StoreListComponent },
          { path: '**', component: PageNotFoundComponent }
        ] 
      },
      /** Item management **/
      { path: 'item-management', component: ItemManagementComponent, canActivate: [UsertypeAuthGuard],
        children: [
          { path: '', redirectTo: 'item-list', pathMatch: 'full' },
          { path: 'item-list', component: ItemListComponent }
        ] 
      },
      /** Item category **/
      { path: 'item-categ-management', component: ItemCategManagementComponent, canActivate: [UsertypeAuthGuard],
        children: [
          { path: '', redirectTo: 'item-categ-list', pathMatch: 'full' },
          { path: 'item-categ-list', component: ItemCategListComponent }
        ] 
      },
      /** All inbounds **/
      { path: 'all-inbound', component: AllInboundComponent, canActivate: [UsertypeAuthGuard],
        children: [
          { path: '', redirectTo: 'inbound-list', pathMatch: 'full' },
          { path: 'inbound-list', component: InboundListComponent },
          { path: '**', component: PageNotFoundComponent }
        ] 
      },

      /** Manual Management **/
      { path: 'manual-management', component:  ManualManagementComponent,canActivate: [UsertypeAuthGuard],
      },

      /** Vender Management **/
      { 
        path: 'vender-management', component:  VenderManagementComponent,canActivate: [UsertypeAuthGuard],
        children : [
          { path: '', redirectTo: 'vender-list', pathMatch: 'full' },
          { path: 'vender-list', component: VenderListComponent },
          { path: '**', component: PageNotFoundComponent }
        ]
      },

       /** Download table csv **/
      { path: 'download-table-csv', component:  DownloadTableCsvComponent,canActivate: [UsertypeAuthGuard],
      },


      
      { path: '**', component: PageNotFoundComponent },
    ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})
  ],
  declarations: [

  ],
  exports: [RouterModule],
  entryComponents: [ 
    
    ImageToolsComponent, ImageToolsComponentDialog 
  ]
})
export class AppRoutingModule { }

export const RoutingComponents = [
  // General components
  AdminUsersComponent, SignInComponent,
  ResetPasswordComponent,SentEmailComponent,SetNewpasswordComponent,ResetCompleteComponent,VerifyComponent,
  MainComponent, DashboardComponent, DbCcComponent,DbHdComponent,DbWhComponent,DbRrComponent,DbVrComponent,
  PageNotFoundComponent,StoreIndexComponent,
  OrderComponent,OrderInputComponent,OrderConfirmComponent,OrderCompleteComponent,
  InquiryComponent,InquiryInputComponent,InquiryConfirmComponent,InquiryCompleteComponent,
  CollectionComponent,CollectionInputComponent,CollectionConfirmComponent,CollectionCompleteComponent,
  MaintenanceComponent,MaintenanceInputComponent, MaintenanceConfirmComponent　,

 
  // Shared Components
  BlankListComponent, ImageToolsComponent,ImageToolsComponentDialog,  ImageToolsDetailComponent, ImageToolsDetailMultipleComponent, 
  PaginatorComponent, ProgressComponent, ToolbarComponent,
];

export const RoutingProviders = [
  AuthGuard, AuthService
]
