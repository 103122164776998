import { venderUrl } from './../shared/data.service';
import { Component, OnInit } from '@angular/core';
import { DownloadTableCsvService } from './shared/download-table-csv.service';
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { ExportToCsv } from 'export-to-csv';
import { variable } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-download-table-csv',
  templateUrl: './download-table-csv.component.html',
  styleUrls: ['./download-table-csv.component.scss']
})
export class DownloadTableCsvComponent implements OnInit {

  fieldsFormGroup : FormGroup
  interests:any;
  selected: any;

  constructor(
    private service: DownloadTableCsvService, 
    private formBuilder: FormBuilder
    ) { }



  tables : any;
  fields : any;
  selectedTable : string;

  ngOnInit() {

    this.genform();
    this.getTable();
  }

  genform(){
    this.fieldsFormGroup = this.formBuilder.group({
      fields: this.formBuilder.array([])
    });
  }
 
  getTable(){
    this.service.getTable().subscribe(res => {
      if(res.success){
        this.tables = res.payload.tables;
      }
    })
  }

  getFields(table : any){ 

    this.genform();

    this.selectedTable = table.value;
    this.service.getFields(table.value).subscribe(res => {
      if(res.success){
        this.fields = res.payload.fields;

        const fields = <FormArray>this.fieldsFormGroup.get('fields') as FormArray;
        this.fields.forEach(function (v) {
          fields.push(new FormControl(v))
        }); 
      }
    })
  }

  onChange(event) {
    
    const fields = <FormArray>this.fieldsFormGroup.get('fields') as FormArray;

    if(event.checked) {
      fields.push(new FormControl(event.source.value))
    } else {
      const i = fields.controls.findIndex(x => x.value === event.source.value);
      fields.removeAt(i);
    }
  }

  submit() {
    if(this.fieldsFormGroup.value.fields.length > 0){
 
      var data = {
        fields : this.fieldsFormGroup.value.fields.toString(),
        table : this.selectedTable
      }
      console.log(data);
      this.service.getData(data).subscribe(res => {
        if(res.success){ 
          const options = { 
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: true, 
            showTitle: false,
            title: this.selectedTable,
            useBom: true,
            useKeysAsHeaders: true,
            filename : this.selectedTable
           };
  
          const csvExporter = new ExportToCsv(options);
  
          csvExporter.generateCsv(res.payload);
        }
      }) 
    }
    
  }
  
}
