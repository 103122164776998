import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs'; 
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class UsertypeAuthGuard implements CanActivate {

  currentUser: any;

  constructor(public auth: AuthService, public router: Router){
    this.currentUser = auth.currentUser
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let userInf: any = JSON.parse(sessionStorage.getItem('currentUser'))
    switch(next.routeConfig.path){
      case "user-management": {
        if((userInf.userrole == 1 && userInf.usertype == 0) ||
           (userInf.userrole == 1 && userInf.usertype == 1) ||
           (userInf.userrole == 1 && userInf.usertype == 2) ||
           (userInf.userrole == 1 && userInf.usertype == 3) ||
           (userInf.userrole == 1 && userInf.usertype == 4) ||
           (userInf.userrole == 1 && userInf.usertype == 5)) 
          this.router.navigate(['/main']);
        else
          return true
      }
      case "inquiry": {
        if((userInf.userrole == 1 && userInf.usertype == 3) ||
           (userInf.userrole == 0 && userInf.usertype == 4) ||
           (userInf.userrole == 1 && userInf.usertype == 4) ||
           (userInf.userrole == 0 && userInf.usertype == 5) ||
           (userInf.userrole == 1 && userInf.usertype == 5)
           )
           this.router.navigate(['/main']);
          else
            return true
      }
      case "order": {
         if((userInf.userrole == 1 && userInf.usertype == 3) ||
            (userInf.userrole == 0 && userInf.usertype == 4) ||
            (userInf.userrole == 1 && userInf.usertype == 4) ||
            (userInf.userrole == 0 && userInf.usertype == 5) ||
            (userInf.userrole == 1 && userInf.usertype == 5)
            )
           this.router.navigate(['/main']);
         else
           return true
      }
      case "collection": {
         if((userInf.userrole == 1 && userInf.usertype == 3) ||
            (userInf.userrole == 0 && userInf.usertype == 4) ||
            (userInf.userrole == 1 && userInf.usertype == 4) ||
            (userInf.userrole == 0 && userInf.usertype == 5) ||
            (userInf.userrole == 1 && userInf.usertype == 5)
            )
            this.router.navigate(['/main']);
          else
            return true
      }
      case "maintenance": {
         if((userInf.userrole == 1 && userInf.usertype == 3) ||
            (userInf.userrole == 0 && userInf.usertype == 4) ||
            (userInf.userrole == 1 && userInf.usertype == 4) ||
            (userInf.userrole == 0 && userInf.usertype == 5) ||
            (userInf.userrole == 1 && userInf.usertype == 5)
            )
            this.router.navigate(['/main']);
         else
           return true
      }
      case "workflow_setting": {
        if((userInf.userrole == 1 && userInf.usertype == 0) ||
           (userInf.userrole == 1 && userInf.usertype == 1) ||
           (userInf.userrole == 1 && userInf.usertype == 3) ||
           (userInf.userrole == 1 && userInf.usertype == 2) ||
           (userInf.userrole == 1 && userInf.usertype == 4) ||
           (userInf.userrole == 1 && userInf.usertype == 5)) 
          this.router.navigate(['/main']);
        else
          return true
      }
      case "waiting_task": { 
        this.router.navigate(['/main']);
      }
      case "chain-management": {
        if((userInf.userrole == 1 && userInf.usertype == 0) ||
           (userInf.userrole == 1 && userInf.usertype == 1) ||
           (userInf.userrole == 1 && userInf.usertype == 3) ||
           (userInf.userrole == 1 && userInf.usertype == 2) ||
           (userInf.userrole == 1 && userInf.usertype == 4) ||
           (userInf.userrole == 1 && userInf.usertype == 5)) 
          this.router.navigate(['/main']);
        else
          return true
      }
      case "store-management": {
        if((userInf.userrole == 1 && userInf.usertype == 1) ||
           (userInf.userrole == 1 && userInf.usertype == 2) ||
           (userInf.userrole == 1 && userInf.usertype == 3) ||
           (userInf.userrole == 1 && userInf.usertype == 4) ||
           (userInf.userrole == 1 && userInf.usertype == 5)) 
          this.router.navigate(['/main']);
        else
          return true
      }
      case "item-management": {
        if((userInf.userrole == 1 && userInf.usertype == 1) ||
           (userInf.userrole == 1 && userInf.usertype == 2) ||
           (userInf.userrole == 1 && userInf.usertype == 3) ||
           (userInf.userrole == 1 && userInf.usertype == 4) ||
           (userInf.userrole == 1 && userInf.usertype == 5)) 
          this.router.navigate(['/main']);
        else
          return true
      }
      case "item-categ-management": {
        if((userInf.userrole == 1 && userInf.usertype == 0) ||
           (userInf.userrole == 1 && userInf.usertype == 1) ||
           (userInf.userrole == 1 && userInf.usertype == 2) ||
           (userInf.userrole == 1 && userInf.usertype == 3) ||
           (userInf.userrole == 1 && userInf.usertype == 4) ||
           (userInf.userrole == 1 && userInf.usertype == 5)) 
          this.router.navigate(['/main']);
        else
          return true
      }
      case "all-inbound": {
        return true
      }
      case "manual-management": {
        if((userInf.userrole == 1 && userInf.usertype == 0) ||
           (userInf.userrole == 1 && userInf.usertype == 1) ||
           (userInf.userrole == 1 && userInf.usertype == 2) ||
           (userInf.userrole == 1 && userInf.usertype == 3) ||
           (userInf.userrole == 1 && userInf.usertype == 4) ||
           (userInf.userrole == 1 && userInf.usertype == 5)) 
          this.router.navigate(['/main']);
        else
          return true
      }
      case "vender-management": {
        if((userInf.userrole == 1 && userInf.usertype == 0) ||
           (userInf.userrole == 1 && userInf.usertype == 1) ||
           (userInf.userrole == 1 && userInf.usertype == 2) ||
           (userInf.userrole == 1 && userInf.usertype == 3) ||
           (userInf.userrole == 1 && userInf.usertype == 4) ||
           (userInf.userrole == 1 && userInf.usertype == 5)) 
          this.router.navigate(['/main']);
        else
          return true
      }
      case "download-table-csv": {
        if((userInf.userrole == 0 && userInf.usertype == 2))
          return true
        else 
          this.router.navigate(['/main']); 
      }case "dashboard": {
        return true
      }case "main": {
        return true
      }default: {
        return true
      }
    }
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    

    return this.canActivate(next, state);
  }
}
