import { MaintenanceOptComponent } from './../../maintenance/maintenance-opt/maintenance-opt.component';
import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../shared/dashboard.service';
import { MatDialog } from '@angular/material';
import { InqConfirmComponent } from '../shared/inq-confirm/inq-confirm.component';
import { OrderConfirmComponent } from '../shared/order-confirm/order-confirm.component';
import { Inquiries, allOrders, OrderRecords } from '../shared/ordered-item.model';
import { ManualList } from '../../manual-management/shared/manual-list-interface';
import { ManualService } from '../../manual-management/shared/manual.service';
import { MaintenanceOptionComponent } from '../shared/maintenance-option/maintenance-option.component';
import { BadgeSocketService } from 'src/app/shared/badge-socket.service';

@Component({
    selector: 'app-db-hd',
    templateUrl: './db-hd.component.html',
    styleUrls: ['./db-hd.component.scss'],
    providers:[BadgeSocketService]
})

export class DbHdComponent implements OnInit {

    inquiries    : Inquiries[] = [];
    orders       : OrderRecords[] = [];
    allOrders    : allOrders[] = [];
    manuals      : ManualList[] = [];
    task_records : any;
    isActive     : '';
    tab          : string

    itemStr: string[] = []

    iCnt: number;
    hasInqData = false;
    hasOrderData = false;
    badges: any[] = []

    constructor(
        public badgeService: BadgeSocketService,
        private service: DashboardService,
        private manualService: ManualService,
        public dialog: MatDialog
    ) { 
      
        console.log('socket response')
        this.badgeService.listenBadgeUpdate('hd').subscribe( res => {
            this.getBadgeCnt()
        })
    }

    ngOnInit() {
        this.getBadgeCnt()
        let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
        this.badgeService.setOnline({user: tmp.userid})
    }

    //ngOnDestroy(): void {
    //  let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
    //  this.badgeService.setOffline({user: tmp.userid})
    //}

    getBadgeCnt(){
        this.service.getDashboardCnt('hd').subscribe(res => {
            this.tchange('inq');
            this.badges = res.result
        })
    }

    getManuals() {
        this.manualService.getManualList().subscribe(res => {
            if (res.success) {
                this.manuals = res.payload.files
            }
        })
    }

    onOrderConfirm(rowData: OrderRecords, o:any) {
        const dialogRef = this.dialog.open(OrderConfirmComponent,
            {
                width: '980px',
                data: {
                    'type': 'hd',
                    'value': {
                      'order_record': rowData,
                      'update_order': o
                    }
                  }
            }
            
        )
        dialogRef.afterClosed().subscribe(res => {
            if (res) this.tchange(this.tab);
        })
        
    }

    //Confirm Modal
    onInqConfirm(rowData: Inquiries) {
        const dialogRef = this.dialog.open(InqConfirmComponent,
            {
                width: '980px',
                data: {
                    'type': 'hd',
                    'mdata': rowData
                }
            }
        )
        dialogRef.afterClosed().subscribe(res => {
            if (res) this.tchange(this.tab);
        })
    }

    changeTab(event) {
        this.tchange(event.tab.textLabel);
    }

    tchange(info) {
        this.tab = info
        this.service.getTaskRecords('hd_' + info).subscribe(res => {
            if (res.success === true) {
                this.isActive = info;
                this.task_records = res.payload.task_records;
                if (info === 'ord') {
                    for (let tmp of res.payload.task_records) {
                        let str: string = ''
                        for (let y = 0; y < tmp.ordered_items.length; y++) {
                            str += tmp.ordered_items[y].name + '×' + tmp.ordered_items[y].qty + (y < (tmp.ordered_items.length - 1) ? ', ' : '')
                        }
                        this.itemStr.push(str)
                    }
                } 
            } else {
                this.hasInqData = true;
            } 
        })
    }

    maintenanceOpt(t) {
        const dialog = this.dialog.open(MaintenanceOptionComponent, {
          width: '980px',
          data: {
            'userType': 'hd',
            'mData': t
          }
    
        })
        dialog.afterClosed().subscribe(res => { 
          this.tchange(this.tab)
        })
    }

}
