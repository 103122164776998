import { StoreService } from './../shared/store.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as prefectures from './city_pref.json'

import * as _moment from 'moment';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { formatDate } from '@angular/common';
//import {default as _rollupMoment} from 'moment';
import { Chain_list } from '../../chain-management/shared/chain.model';
const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
}

export class customValidationService {
  static checkLimit(min: number, max: number): ValidatorFn {
   return (c: AbstractControl): { [key: string]: boolean } | null => {
       if (c.value && (isNaN(c.value) || c.value < min || c.value > max)) {
           return { 'range': true };
       }
       return null;
   };
 }
}

export interface KeyValue {
  key: any;
  value: string;
}



@Component({
  selector: 'app-store-opt',
  templateUrl: './store-opt.component.html',
  styleUrls: ['./store-opt.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class StoreOptComponent implements OnInit {

  storeForm: FormGroup;

  date = new FormControl(moment());

  opt: string

  isLoad = false
  showLoading = false
  showErrorText = false
  errorText = ''

  prefs: KeyValue[] = []
  prefsControl = new FormControl('', [Validators.required]);
  city: KeyValue[] = []
  cityControl = new FormControl('', [Validators.required]);

  pref = prefectures.default[0]
  disabledSubmit: boolean = false;
  chain: Chain_list[] = [];

  tierLvl: KeyValue[] = [
    { key: 100, value: "1" },
    { key: 101, value: "1+" },
    { key: 200, value: "2" },
    { key: 300, value: "3" },
    { key: 999, value: "その他" }
  ]

  constructor(public dialogRef: MatDialogRef<StoreOptComponent>, private service: StoreService,
    @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder) { }

  
  ngOnInit() {
    if(this.data.option === 'edit') this.opt = '変更'
    else this.opt = '店舗新規追加'

    console.log(this.data)
    for(let x = 1; x <= 48; x++){
      if(x < 10) this.prefs.push({key: "0"+x, value: this.pref["0"+x].name})
      else this.prefs.push({key: ""+x, value: this.pref[""+x].name})
    }
    this.chain = this.data.chains
    console.log('chainss', this.chain)
    this.createForm()
  }

  popCity: boolean = false

  showCity(event: any, key: string){
    if(this.data.option === 'add') this.popCity = true
    if(event.isUserInput){
      if(this.popCity){
        this.city = undefined
        this.city = []
        for(let x = 0; x < this.pref[key].city.length; x++){
          let temp = this.pref[key].city[x]
          this.city.push({ key: temp.citycode, value: temp.city })
        }
      }
      this.popCity = true
    }
  }

  onSubmit(){
    this.isLoad = true
    this.showLoading = true
    let raw = this.storeForm.getRawValue()
    console.log(this.storeForm.invalid, this.date.invalid)
    if (this.storeForm.invalid || this.date.invalid) {
      this.showLoading = false
      this.showErrorText = true
      this.errorText = '重要なフィールドを記入してください'
      return
    };
    // this.disabledSubmit = true;
    let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
    let body: any
    if(this.data.option === 'add'){
      body = {
        //'chain_no': Number(raw.chain_name.chain_no),
        'chain_no': raw.chain_name.chain_no,
        'chain_store_id': raw.chain_store_id,
        'store_name': raw.store_name,
        'tel': raw.tel,
        'ship_name': raw.ship_name,
        'tier': raw.tier.key,
        'area': raw.area,
        'post_code': raw.post_code,
        'pref': raw.pref.value,
        'city': raw.city.value,
        'address': raw.address,
        'loc_type': raw.loc_type,
        'square': raw.square != null ? raw.square : 0,
        'loc_station': raw.loc_station,
        'population': raw.population != null ? raw.population : 0,
        'seller_type': raw.seller_type,    
        'closed_flg': raw.closed_flg == false || raw.closed_flg == null ? 0 : 1, //raw.closed_flg != null ? raw.closed_flg : 0, let availability = req.body.availability == 'false'? 0 : 1;
        'sa_flg': raw.sa_flg != null ? raw.sa_flg : 0,
        'round_flg': raw.round_flg != null ? raw.round_flg : 0,
        'round_finish_date': formatDate(new Date(this.date.value), 'yyyy-MM-dd hh:mm:ss', 'en-US'),
        'report_flg': raw.report_flg != null ? raw.report_flg : 0,
        'cr_type': raw.cr_type != null ? raw.cr_type : 0,
        'company_in_charge': raw.company_in_charge,
        'rounder': raw.rounder,
        'corner': raw.corner,
        'ctrl_flg': raw.ctrl_flg != null ? raw.ctrl_flg : 0,
        'utencil_type': raw.utencil_type != null ? raw.utencil_type : 0,
        'wms_id': raw.wms_id,
        'created_by': tmp.userid,
        'tz_zero_padding': raw.tz_zero_padding,
        'retail_tier': raw.retail_tier
      }
      console.log(body)
    }else if(this.data.option === 'edit'){
      body = {
        //'store_no': this.data.desc.store_no,
        'id': this.data.desc.id,
        'chain_store_id': raw.chain_store_id,
        'chain_no': raw.chain_name.chain_no,
        'store_name': raw.store_name,
        'tel': raw.tel,
        'ship_name': raw.ship_name,
        'tier': raw.tier.key,
        'area': raw.area,
        'post_code': raw.post_code,
        'pref': raw.pref.value,
        'city': raw.city.value,
        'address': raw.address,
        'loc_type': raw.loc_type,
        'square': raw.square != null ? raw.square : 0, 
        'loc_station': raw.loc_station,
        'population': raw.population != null ? raw.population : 0,
        'seller_type': raw.seller_type,
        'closed_flg': raw.closed_flg == false || raw.closed_flg == null ? 0 : 1, 
        'sa_flg': raw.sa_flg != null ? raw.sa_flg : 0,
        'round_flg': raw.round_flg != null ? raw.round_flg : 0,
        'round_finish_date': formatDate(new Date(this.date.value), 'yyyy-MM-dd', 'en-US'),
        'report_flg': raw.report_flg != null ? raw.report_flg : 0,
        'cr_type': raw.cr_type != null ? raw.cr_type : 0,
        'company_in_charge': raw.company_in_charge,
        'rounder': raw.rounder,
        'corner': raw.corner,
        'ctrl_flg': raw.ctrl_flg != null ? raw.ctrl_flg : 0,
        'utencil_type': raw.utencil_type != null ? raw.utencil_type : 0,
        'wms_id': raw.wms_id,
        'updated_by': tmp.userid,
        'tz_zero_padding': raw.tz_zero_padding,
        'retail_tier': raw.retail_tier
      }
    }
    this.service.storeOpt(body, this.data.option)
    .subscribe(res => {
      if(res){
        let mres: any = res
        if(mres.body.success)
          this.dialogRef.close(true)
        else{
          this.showLoading = false
          this.showErrorText = true
          this.errorText = mres.body.error
        }
      }else{
        this.showLoading = false
        this.showErrorText = true
        this.errorText = '接続の問題が再試行してください'
      }
    })
  }

  cancelSave(){
    this.isLoad = false
    this.showLoading = false
    this.showErrorText = false
  }

  createForm(): void {
    let chainInd: any
    let cityObj
    let prefObj
    let prefKey: string
    let tierInd: number

    if(this.data.option === 'edit'){
      for(let x = 0; x < this.prefs.length; x++)
        if(this.prefs[x].value === this.data.desc.pref){
          prefObj = x
          prefKey = this.prefs[x].key
        }
  
      if(this.data.desc.pref === "" || this.data.desc.pref === null || this.data.desc.pref === " "){
          prefObj = 47
          prefKey = this.prefs[47].key
      } 

      for(let x = 0; x < this.pref[prefKey].city.length; x++){
        let temp = this.pref[prefKey].city[x]
        this.city.push({ key: temp.citycode, value: temp.city })
      }
  
      for(let x = 0; x < this.city.length; x++){
        if(this.data.desc.city === this.city[x].value){
          cityObj = x
        }
      }
      if(this.data.desc.city === "" || this.data.desc.city === null || this.data.desc.city === " " ){
        cityObj = 0
      } 

      for(let x = 0; x < this.chain.length; x++){
        //if(this.chain[x].chain_no === this.data.desc.chain_no) chainInd = x
        if(+this.chain[x].chain_no === +this.data.desc.chain_no) chainInd = x
      }

      for(let x = 0; x < this.tierLvl.length; x++){
        if(this.tierLvl[x].key === this.data.desc.tier) tierInd = x
      }
    }

    
    let raw = this.data.desc
    this.storeForm = this.formBuilder.group({
      chain_name: [ this.data.option === 'edit' ? this.chain[chainInd] : this.chain, Validators.required ],
      store_name: [ raw.store_name, [Validators.required, Validators.maxLength(100)] ],
      chain_store_id: [ raw.chain_store_id ],
      tel: [ raw.tel ],
      ship_name: [ raw.ship_name],
      city: [ this.data.option === 'edit' ? this.city[cityObj] : '', Validators.required ],
      pref: [ this.data.option === 'edit' ? this.prefs[prefObj] : '', Validators.required ],
      tier: [ this.data.option === 'edit' ? this.tierLvl[tierInd] : '', Validators.required ],
      area: [ raw.area  ],
      post_code: [ raw.post_code, [Validators.required ]],
      address: [ raw.address, [Validators.required] ],
      loc_type: [ raw.loc_type,  ],
      square: [ raw.square],
      loc_station: [ raw.loc_station ],
      population: [ raw.population ],
      seller_type: [ raw.seller_type ? raw.seller_type : 'N/A' ],
      closed_flg: [raw.closed_flg],    
      sa_flg: [ raw.sa_flg ],
      round_flg: [ raw.round_flg ],
      report_flg: [ raw.report_flg ],
      cr_type: [ raw.cr_type ? raw.cr_type : 'N/A' ],
      company_in_charge: [ raw.company_in_charge ],
      rounder: [ raw.rounder ],
      corner: [ raw.corner ],
      ctrl_flg: [ raw.ctrl_flg],
      utencil_type: [ raw.utencil_type ],
      wms_id: [ raw.wms_id ],
      tz_zero_padding: [ raw.TZ_zero_padding ],
      retail_tier: [ raw.retail_tier ]
    });
    if(this.data.option === 'edit')
      this.date.setValue(new Date(formatDate(raw.round_finish_date, 'longDate', 'en-US')))
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }  


}
