import { Injectable } from '@angular/core';
import { DataService, systemUrl } from '../../shared/data.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadTableCsvService {

  constructor(private dataService: DataService) { }

  getTable(){
    return this.dataService.get(systemUrl + '/get-table-csv', null);
  }
  
  getFields(table: string){
    return this.dataService.get(systemUrl + '/fields/', table);
  }

  getData(body : any){
    return this.dataService.post(systemUrl, body);
  }

}
