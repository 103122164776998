import { HttpEvent, HttpEventType, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { UserListService } from './../shared/user-list.service';
import { AdminUserService } from './../../admin-users/shared/admin-user.service';
import { KeyValue } from './../../item-categ-management/item-categ-opt/item-categ-opt.component';
import { Location, formatDate } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { VenderService } from '../../vender-management/shared/vender.service';
import { Vender_list } from '../../vender-management/shared/venders.model';

import * as _moment from 'moment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';
//import {default as _rollupMoment} from 'moment';
const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface KeyValue {
  key: string;
  value: string;
}

@Component({
  selector: 'app-user-add-dialog',
  templateUrl: './user-add-dialog.component.html',
  styleUrls: ['./user-add-dialog.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class UserAddDialogComponent implements OnInit {

  acc_date = new FormControl(moment());
  pass_date = new FormControl(moment());
  userForm: FormGroup;

  role: KeyValue[] = [
    { key: "0", value: "管理者" },
    { key: "1", value: "一般" }
  ];
  genderControl = new FormControl('');

  userType: KeyValue[] = [
    { key: "0", value: "コールセンター" },
    { key: "1", value: "ラウンダー" },
    { key: "2", value: "博報堂" },
    { key: "3", value: "Google" },
    { key: "4", value: "倉庫" },
    { key: "5", value: "ベンダー" },
    { key: "6", value: "ショップ" },
  ];
  
  userTypeControl = new FormControl('');

  user_event: string
  event_str: string
  imgFile: File
  isUpload = false
  isLoad = false;
  showLoading = false
  showUploadError = false
  showErrorText = false
  errorText = ''
  venders: Vender_list[] = [];
  venID:any ;

  constructor(public dialogRef: MatDialogRef<UserAddDialogComponent>, private service: UserListService, public Venderservice: VenderService,
    @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder) { }

  ngOnInit() {
    
    if(this.data.option === 'edit'){
      this.event_str = '変更'
      this.localUrl = this.data.desc.icon
    }
    else this.event_str = 'ユーザー新規追加'
    
    this.utype_keyclicked = true;
    this.createForm()
    this.getVender()
  }

  getVender() {
    //this.Venderservice.getVenderList().subscribe(res => {
     // if(res.success){
    //    this.venders = res.payload.venders
    //  }
    //}) 
    this.venders = this.data.venders   
  }

  //localUrl = "/assets/img/no-image.jpg";
  localUrl = ""; 
  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
          this.localUrl = event.target.result;
      }
      reader.readAsDataURL(event.target.files[0]);
      this.imgFile = event.target.files[0];
      this.isUpload = true
    }
  }

  // disabledSubmit: boolean = false 
  utype_keyclicked : any;
  showVenders(event: any, key: string){
    if (event.source.selected) {
      if(key === '5'){
        this.utype_keyclicked = false;
        this.venders = this.data.venders
      } else { 
        this.venders = [];
        this.utype_keyclicked = true;
        
      } 
    }
  }

  submit(imgUrl: string){
    if(imgUrl.length === 0 && this.data.option === 'edit') imgUrl = this.data.desc.icon
    let raw = this.userForm.getRawValue()
    // if (this.userForm.invalid || this.acc_date.invalid || this.pass_date.invalid) {
    //   this.showLoading = false
    //   this.showUploadError = false
    //   this.showErrorText = true
    //   this.errorText = '重要なフィールドを記入してください'
    //   return
    // };
    // if(raw.password !== raw.password2) {
    //   this.showLoading = false
    //   this.showUploadError = false
    //   this.showErrorText = true
    //   this.errorText = 'パスワードを確認してください'
    //   return
    // };
    // this.disabledSubmit = true;
    let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
    let accDate = formatDate(new Date(this.acc_date.value), 'yyyy-MM-dd', 'en-US')
    let passDate = formatDate(new Date(this.pass_date.value), 'yyyy-MM-dd', 'en-US')
    let body: any
    
    if (raw.vender == null || raw.user_type.key != 5 ){
      this.venID = 0
    } else {
      this.venID = raw.vender.id
    }

    if(this.data.option === 'add'){
      body = {
        'mail_address': raw.email,
        'passwd': raw.password,
        'role': raw.user_role.key,
        'type': raw.user_type.key,
        'name': raw.name,
        'company': raw.company,
        'company_tel': raw.tel_number,
        'cell_no': raw.cell_number,
        'icon': imgUrl , 
        'g_account': raw.g_account,
        'accnt_expire': accDate,
        'expire': passDate,
        'notes': raw.notes,
        'created_by': tmp.userid,
        'vender': this.venID,   
      }
    }else if(this.data.option === 'edit'){
      body = {
        'mail_address': raw.email,
        'passwd': raw.password,
        'role': raw.user_role.key,
        'type': raw.user_type.key,
        'name': raw.name,
        'company': raw.company,
        'company_tel': raw.tel_number,
        'cell_no': raw.cell_number,
        'icon': imgUrl , 
        'g_account': raw.g_account,
        'accnt_expire': accDate, 
        'expire': passDate,
        'notes': raw.notes,
        'user_no': this.data.desc.user_no,
        'updated_by': tmp.userid,
        'vender': this.venID,  
      }
    }
    this.service.userOpt(body, this.data.option)
    .subscribe(res => {
      if(res){
        this.dialogRef.close(true)
      }else{
        console.log(res)
        this.showLoading = false
        this.showUploadError = false
        this.showErrorText = true
        this.errorText = '接続の問題が再試行してください' 
      }
    })
  }

  imgUpload(){
    this.isLoad = true
    let raw = this.userForm.getRawValue()
    if (this.userForm.invalid || this.acc_date.invalid || this.pass_date.invalid) {
      this.showLoading = false
      this.showUploadError = false
      this.showErrorText = true
      this.errorText = '重要なフィールドを記入してください'
      return
    };
    if(raw.password !== raw.password2) {
      this.showLoading = false
      this.showUploadError = false
      this.showErrorText = true
      this.errorText = 'パスワードを確認してください'
      return
    };

    this.showLoading = true
    this.showUploadError = false
    this.showErrorText = false

    if(!this.isUpload){
      this.submit('')
      return
    }
    let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
    let body = {
      'source_id': 1,
      'created_by': tmp.userid,
      'file_description': 'none'
    }
    this.service.upload(body, this.imgFile).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.Sent:
            console.log('Uploading data...')
            return
    
          case HttpEventType.Response:
            let res: any = event.body
            console.log(res.payload.filename);
            this.submit(res.payload.filename)
            return
        }
      }),
      catchError((err: HttpErrorResponse) => {
        console.log(err)
        this.showLoading = false
        this.showUploadError = true
        this.showErrorText = false
        return Observable.throw(err);
      })
    ).subscribe()
  }

  createForm(): void {
    this.venders = this.data.venders
    let venderObj
    for(let x = 0; x < this.venders.length; x++){
        if(this.venders[x].id === this.data.desc.vender){
          venderObj = x
        }
    }
    let formDesc = this.data.desc
    this.userForm = this.formBuilder.group({
      name: [ formDesc.name, Validators.required ],
      g_account: [ formDesc.g_account ],
      cell_number: [ formDesc.cell_no ],
      tel_number: [ formDesc.company_tel ],
      user_role: [ this.role[formDesc.role], Validators.required ],
      user_type: [ this.userType[formDesc.type], Validators.required ],
      company: [ formDesc.company, Validators.required ],
      notes: [ formDesc.notes ],
      email: [ formDesc.mail_address, Validators.required ],

      //password: [ ],
      //password2: [ ],


      password: [
        '',
        [
          Validators.maxLength(24),
          Validators.minLength(8),
          Validators.pattern('^[a-zA-Z0-9@#$%^&*()]+$')
        ]
       ],
      password2: [
        '',
        [
          Validators.maxLength(24),
          Validators.minLength(8),
          Validators.pattern('^[a-zA-Z0-9@#$%^&*()]+$')
        ]
      ],
      vender: [ this.venders[venderObj]  ] 

    });
    if(this.data.option === 'edit'){
      this.pass_date.setValue(new Date(formatDate(formDesc.expire, 'longDate', 'en-US')))
      this.acc_date.setValue(new Date(formatDate(formDesc.accnt_expire, 'longDate', 'en-US')))
    }
    
  }

  cancelSave(){
    this.isLoad = false
    this.showLoading = false
    this.showUploadError = false
    this.showErrorText = false
  }

  saveWithNoImage(){
    this.isLoad = true
    this.showLoading = true
    this.showUploadError = false
    this.showErrorText = false
    this.submit('')
  }

}
