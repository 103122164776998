import { Observable } from 'rxjs/Observable';
import { ExportColumns } from './../shared/order-confirm/export-columns';
import { ExportUtility } from './../shared/order-confirm/export-utility';
import { DataService, shipRecord } from './../../shared/data.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from '../shared/dashboard.service';
import { MatTableDataSource, MatSort, MatSnackBar } from '@angular/material';
import { MatDialog } from '@angular/material';
import { InqConfirmComponent } from '../shared/inq-confirm/inq-confirm.component';
import { OrderConfirmComponent } from '../shared/order-confirm/order-confirm.component';
import { Inquiries, Orders,OrderRequests, OrderRecords, OrderedItems,allOrders, Items } from '../shared/ordered-item.model';
import { Papa } from 'ngx-papaparse';
import { UploadedDialogComponent } from './uploaded-dialog/uploaded-dialog.component';
import { formatDate } from '@angular/common';
import { StoreService } from '../../store/shared/store.service';
import { Store } from '../../store/shared/store.model';
import { CollConfirmComponent } from '../shared/coll-confirm/coll-confirm.component';

export interface JsonFormat {
  wms_order_no: string;
  order_no: string;
  item_no: string;
  item_name: string;
  item_qty: string;
  item_amount: string;
  client_id: string;
  unit_price: string;
  payment_method: string;
  tracking_code: string;
}

export interface MainJson {
  mainjson: JsonFormat[]
}

export interface CsvFormat{
  item_no: string
  name: string
  qty: string
  total_amount: string
  blank: string
  wms_id: string
  price: string
  def: string
  order_no: string
}

@Component({
  selector: 'app-db-wh',
  templateUrl: './db-wh.component.html',
  styleUrls: ['./db-wh.component.scss'],
  providers: [Papa]
})

export class DbWhComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;

  inquiries: Inquiries[] = [];
  orders: Orders[] = [];
  orderRequests: OrderRequests[] = []; //meged idea
  orderRecodes: OrderRecords[] = []; //from ORDER RECODE
  orderedItems: OrderedItems[] = []; //from ORDERED ITEM
  allOrders: allOrders[] = [];
  items: Items[] = [];
  store: Store[] = []; 
  task_records: {};
  itemStr: string[] = []

  iCnt: number;
  hasInqData = false;
  tab: string = 'inq'
  isActive: '';
  badges: any[] = []

  dataSource: MatTableDataSource<Orders>;
  displayedColumns = ['order_ref_no', 'ordered_date', 'register_name', 'urg_flg', 'items', 'fileDL'];

  title: string = " ダッシュボード（倉庫）";
  constructor(
    private service: DashboardService,
    public dialog: MatDialog,
    private papa: Papa,
    private serviceStore:StoreService,
    public snackBar: MatSnackBar
  ) { 
    // console.log('socket response')
    // this.badgeService.listenBadgeUpdate('wh').subscribe( res => {
    //   console.log('socket response', res)
    // })
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.orders);
    this.dataSource.sort = this.sort;
    this.getBadgeCnt()
    this.getOrder()
    let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
  }

  //ngOnDestroy(): void {
  //  let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
  //  this.badgeService.setOffline({user: tmp.userid})
  //}
  
  getBadgeCnt(){
    this.service.getDashboardCnt('wh').subscribe(res => {
      this.getTasks(this.tab)
      this.badges = res.result
    })
  }

  getOrder(){
    this.service.getTaskRecords('wh_ord').subscribe(res => {
      this.orders = res.payload.task_records
      console.log('getOrder1', res.payload)
      var x = res.payload.task_records.filter(x => x.order_record.level === 1);
      console.log('getOrder2', x)
      this.dataSource.data = x
      this.hasInqData = true;
    })
  }

  changeTab(event) {
    this.getTasks(event.tab.textLabel);
  }

  getTasks(info) {
    this.tab = info
    this.inquiries = []
    this.service.getTaskRecords('wh_' + info).subscribe(res => {
      this.task_records = res.payload.task_records
      this.hasInqData = true;
      this.isActive = info;
      if (info === 'ord') {
        for (let tmp of res.payload.task_records) {
          let str: string = ''
          for (let y = 0; y < tmp.ordered_items.length; y++) {
            str += tmp.ordered_items[y].name + '×' + tmp.ordered_items[y].qty + (y < (tmp.ordered_items.length - 1) ? ', ' : '')
          }
          this.itemStr.push(str)
        }
      }
    })
    this.orders = []
  }

  //Confirm Modal
  onInqConfirm(rowData: Inquiries) {
    const dialogRef = this.dialog.open(InqConfirmComponent,
      {
        width: '980px',
        data: {
          'type': 'wh',
          'mdata': rowData
        }
      }
    )
    dialogRef.afterClosed().subscribe(res => {
      if (res) this.getTasks(this.tab)
    })
  }

  onCollConfirm(rowData: Inquiries){
    const dialogRef = this.dialog.open(CollConfirmComponent,
      {
        width: '980px',
        data: {
          'type': 'wh',
          'mdata': rowData
        }
      }
    )
    dialogRef.afterClosed().subscribe(res => {
      if (res) this.getTasks(this.tab)
    })
  }

  //confirming individually
  onOrderConfirm(rowData: OrderRecords,o:any) {
    const dialogRef = this.dialog.open(OrderConfirmComponent,
        {
            width: '980px',
            data: {
                'type': 'wh',
                'value': {
                  'order_record': rowData,
                  'update_order': o
                }
              }
        }
        
    )
    dialogRef.afterClosed().subscribe(res => {
      if(res) {
        this.getOrder();
        this.getTasks(this.tab)
      }
    })
  }

  updateEscStat_toCC(sss:any){
    let user: any = JSON.parse(sessionStorage.getItem('currentUser'));
    let observables = new Array();
    for(let tmp of sss.ship_records){
      let escQueryDoneDL = {
        order_ref_no: tmp.order_no,
        esc_status: 6,
        esc_direction: 1,
        updated_by: user.userid,
      }
      observables.push(this.service.updateEsc(escQueryDoneDL))
    }
    Observable.forkJoin(observables)
    .subscribe(res => {
      console.log('Escation updated', res)
      this.getOrder();
    })
  }

  fromCsv: JsonFormat[] = []
  
  onSelectFile(event: any) {

    let ids: string[] = []
    
    let options = {
      complete: (results, file) => {
        let csv: any = results.data

        console.log('raw csv', csv)
        for (let x = 0; x < csv.length; x++) {
          
          if (csv[x].length > 1 && x != 0) {
            let tmp: any = {
              wms_order_no: csv[x][0],
              order_no: csv[x][1],
              item_no: csv[x][2],
              item_name: csv[x][3],
              item_qty: csv[x][4],
              item_amount: csv[x][5],
              client_id: csv[x][6],
              unit_price: csv[x][7],
              payment_method: csv[x][8],
              tracking_code: csv[x][9]
            }

            ids.push(csv[x][1])
            this.fromCsv.push(tmp)
          }
        }
        this.service.saveMultiCSV(ids).subscribe(res => {
          //this.snackBar.open('Successfully save to call center', null, {
          //  duration: 2000,
          //});
          let finalJson: any = {
            ship_records: this.fromCsv
          }
          this.service.saveCSV(JSON.stringify(finalJson))
          .subscribe(res => {
            if(res.success == true){
              const dialogRef = this.dialog.open(UploadedDialogComponent,
                { width: '550px', height:'150px', data: {message: 'アップロードされました'} }
              )
              this.updateEscStat_toCC(finalJson)
            } else {
              const dialogRef = this.dialog.open(UploadedDialogComponent,
                { width: '550px', height:'150px', data: {message: 'アップロードcsvファイルに失敗しました'}}
              )
            } 
          })
  
        })
      }
    };
    this.papa.parse(event.target.files[0], options);
  }

  insertedRecord = 0
  expectedRecord = 0
 
  dlAll(): void{
    this.insertedRecord = 0
    this.expectedRecord = 0
    let myCSV: CsvFormat[] = []
    let tmp = JSON.stringify(this.dataSource.data)
    let mydata: any[] = JSON.parse(tmp)

    for(let x = 0; x < mydata.length; x++){
      for(let y = 0; y < mydata[x].ordered_items.length; y++){
        this.expectedRecord++
      }
    }

    for(let x = 0; x < mydata.length; x++){
      
      for(let y = 0; y < mydata[x].ordered_items.length; y++){
        
        this.serviceStore.getStore(mydata[x].order_record.store_id).subscribe(res => {
          if(res.success === true) {
            this.store = res.payload.store;
            let hoped_date = ""
            let hoped_format = ""
            
            if ( formatDate(new Date(mydata[x].order_record.hoped_date), 'yyyy-MM-dd', 'en-US').toString() == "1970-01-01"){
              hoped_date = "0000"
            }
            else {
              hoped_date = formatDate(new Date(mydata[x].order_record.hoped_date), 'MMdd', 'en-US')
            }
            let hoped_time :any
            if (mydata[x].order_record.hoped_time == 3){
              hoped_time = ''
            }else{
                hoped_time = mydata[x].order_record.hoped_time
            }

            hoped_format = hoped_date.toString() + hoped_time.toString()  
            let woPrefCity = [];
            let woPref = this.store[0].address.split(this.store[0].pref.toString())
            woPrefCity = woPref[woPref.length -1].toString().split(this.store[0].city.toString())

            let address_3 = ""
            let address_3_add = ""
            let address_3_pic = ""
            address_3_add = mydata[x].order_record.addressee +" 御中"
            let address_3_space = " ";
            address_3_pic = mydata[x].order_record.person_in_charge +" 様"
            if (mydata[x].order_record.addressee == "" || mydata[x].order_record.addressee == null ){
              address_3_add = ""; address_3_space= ""
            } 
            if (mydata[x].order_record.person_in_charge == "" || mydata[x].order_record.person_in_charge == null ){
              address_3_pic = ""; address_3_space= ""
            }
            address_3 = address_3_add.toString() + address_3_space.toString() + address_3_pic.toString()

            let toCSV: any = {
            "注文ID" : mydata[x].ordered_items[y].order_no,
            "注文日" :  formatDate(new Date(mydata[x].order_record.created_date), 'yyyy-MM-dd', 'en-US'),
            "注文時間" : formatDate(new Date(mydata[x].order_record.created_date), 'HH:mm:ss', 'en-US'),
            "商品名" :  mydata[x].ordered_items[y].name,
            "商品番号" : mydata[x].ordered_items[y].item_ref_no,
            "個数" : mydata[x].ordered_items[y].qty,
            "単価" :  mydata[x].ordered_items[y].price,
            "注文者名字"	: '',
            "注文者名前"	: '',
            "メールアドレス"	: '',
            "注文者郵便番号1"	: '',
            "注文者郵便番号2"	: '',
            "注文者住所1:都道府県"	: '',
            "注文者住所2:都市区"	: '',
            "注文者住所3:町以降"	: '',
            "注文者電話番号1"	: '',
            "注文者電話番号2"	: '',
            "注文者電話番号3"	: '',
            "送付先名字"	: '',
            "送付先名前" :  this.store[0].store_name, //mydata[x].order_record.addressee + " " + mydata[x].order_record.person_in_charge ,
            "送付先郵便番号1" : this.store[0].post_code,
            "送付先郵便番号2" : '',
            "注文者住所1" : this.store[0].pref,
            "注文者住所2" : woPref[woPref.length -1],
            "注文者住所3" : address_3,
            "送付先電話番号1" : this.store[0].tel,
            "送付先電話番号2" : '',
            "送付先電話番号3" : '',
            "決済方法": '銀行振込',
            "配送日指定": hoped_format,
            "配送方法" : '',
            "時間帯指定": '', 
            "品代合計(税込)"	: '',
            "送料(税込)"	: '',
            "代引き手数料(税込)"	: '',
            "合計金額(税込)"	: '',
            "調整額"	: '',
            "割引額(ポイント利用額)"	: '',
            "ポイント残高"	: '',
            "請求金額(税込)"	: '',
            "備考"	: '',
            "別名1"	: '',
            "荷扱い1"	: '',
            "メール通知"	: '',
            "入力機種"	: '',
            "通知メッセージ"	: '',
            "止め置き"	: '',
            "営業所コード"	: '',
            "熨斗フラグ"	: '',
            "ギフトフラグ"	: '',
            "倉庫備考"	: ''  
            }
            myCSV.push(toCSV)
          }
          if(this.expectedRecord <= ++this.insertedRecord){
            ExportUtility.downloadcsv(myCSV, "data_");
            this.updateEscStat()
          }
        })
      }
    }
  }

  updateEscStat(){
    let user: any = JSON.parse(sessionStorage.getItem('currentUser'));
    let observables = new Array();
    let mydata: any[] = this.orders

    for(let tmp of mydata){
      let tmpuser: any = JSON.parse(sessionStorage.getItem('currentUser'))

      var body = {
        'head': 1,
        'reference': tmp.order_record.order_ref_no,
        'type' : 1,
        'user_type': tmp.order_record.esc_direction,
        'user': tmpuser.userid,
        'level': 2,
        'user_id': tmpuser.userid,
        'cc_view': 1,
        'hd_view': 1,
        'wh_view': 1,
        'rr_view': 1,
      }
      observables.push(this.service.saveTask(body))
    }
    Observable.forkJoin(observables)
    .subscribe(res => {
      console.log('Escation updated', res)
      this.getOrder();
    })

  }
  
}
