import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Items } from '../shared/item.model';

export interface KeyValue {
  key: any;
  value: string;
}

@Component({
  selector: 'app-item-view',
  templateUrl: './item-view.component.html',
  styleUrls: ['./item-view.component.scss']
})

export class ItemViewComponent implements OnInit {

  localUrl = "/assets/images/upload2.png";

  

  constructor(public dialogRef: MatDialogRef<ItemViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Items) { }

    mnfc: { [key: number]: string } =
    {
      0: '   ',
      1: 'メーカー1',
      2: 'メーカー2',
      3: 'メーカー3',
      4: 'メーカー4',
    };

  posib: { [key: number]: string } =
  {
    0: '可',
    1: '不可',
  };
   
  availability: { [key: number]: string } =
  {
    0: '不可', //Not  available
    1: '可',  // Available
  };

  tierLvl: KeyValue[] = [
    { key: 100, value: "1" },
    { key: 101, value: "1+" },
    { key: 200, value: "2" },
    { key: 300, value: "3" },
    { key: 999, value: "その他" }
  ]

  suffix =[ "本" ,"枚" ,"冊" , "機" ,"個" , "ケ" ];

  ngOnInit() {
    if(this.data.img == "" || this.data.img == " " || this.data.img == null ){
      this.data.img = ""
    }
  }

  getTier(key: number){
    let ret: string
    for(let x = 0; x < this.tierLvl.length; x++){
      if(this.tierLvl[x].key === key) return this.tierLvl[x].value
    }
  }

}
