import { Component, OnInit, ViewChild, OnDestroy, Input, HostListener, Pipe, PipeTransform } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Store } from '../../store/shared/store.model';
import { StoreService } from '../../store/shared/store.service';
import { MatDialog, MatSort, MatTableDataSource } from '@angular/material';
import { Order } from '../shared/order.model';
import { OrderService } from '../shared/order.service';
import { OrderData, RelationalRefData } from '../shared/order.prov';
import { OnBeforeunload } from '../shared/order.guard';
import { Moment } from 'moment'; // 追加
import { EditOrder } from 'src/app/shared/edit-order.prov';
import { map, catchError } from 'rxjs/operators';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BadgeSocketService } from 'src/app/shared/badge-socket.service';

@Component({
  selector: 'app-order-input-child',
  templateUrl: './order-confirm.component.html',
  styleUrls: ['./order-confirm.component.scss'],
  providers: [StoreService, OrderService, BadgeSocketService]
})

@Pipe({
  name: 'dateTimeFormat'
})

export class OrderConfirmComponent implements OnInit, OnBeforeunload {

  urgencyFlag: boolean = false;
  order: Order[] = [];
  store: Store;
  queryOrder = {};
  id: string; //for store ID
  hasCart: boolean = true;
  error: any;
  showError: boolean = false;
  date: number;
  moment: Moment;
  isLoading: boolean = false;
  rel = '';
  itemDesc: any;
  toUpdate: any[] = []
  btnDisable = false

  constructor(
    public badgeService: BadgeSocketService,
    private route: ActivatedRoute,
    private router: Router,
    private storeService: StoreService,
    private orderService: OrderService,
    private dialog: MatDialog,
    private orderData: OrderData,
    private relData: RelationalRefData,
    public editOrder: EditOrder
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.store = this.orderData.store;
    this.order = this.orderData.order;
    this.urgencyFlag = this.orderData.flag;
    this.rel = this.relData.inq_ref;
    this.itemDesc = this.orderData.order_desc

    if (this.orderData.order) {
      this.hasCart = true;
    } else {
      this.hasCart = false;
    }

    if (this.editOrder.edit_order == null) return

    let isNew = true;
    for (let ord of this.orderData.order) { // new value
      for (let upd of this.editOrder.edit_order.ordered_items) { // initial value
        if (ord.item_no === upd.item_no) {
          let tmp = {
            'item_id': ord.item_id,
            'item_no': ord.item_no,
            'qty': ord.qty - upd.qty
          }
          this.toUpdate.push(tmp)
          isNew = false
        }
      }
      if (isNew) {
        let tmp = {
          'item_id': ord.item_id,
          'item_no': ord.item_no,
          'qty': ord.qty
        }
        this.toUpdate.push(tmp)
      }
      isNew = true
    }
    let isDel = true;
    for (let upd of this.editOrder.edit_order.ordered_items) {
      for (let ord of this.orderData.order) {
        if (ord.item_no === upd.item_no) isDel = false
      }
      if (isDel) {
        let tmp = {
          'item_id': upd.item_id,
          'item_no': upd.item_no,
          'qty': 0 - upd.qty
        }
        this.toUpdate.push(tmp)
      }
      isDel = true
    }
  }

  getSuffix(key: number) {
    key.toString();
    return this.orderService.sufList[key].value;
  }

  shouldConfirmOnBeforeunload() {
    return !!this.hasCart;
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnload(e: Event) {
    if (this.shouldConfirmOnBeforeunload()) {
      e.returnValue = true;
    }
  }

  onBack() {
    this.orderData.order = this.order;
    this.router.navigate(['/main/order/input/' + this.id]);
  }

  err_code = '';
  err_msg = '';

  toComplete() {
    //caling api
    this.btnDisable = true
    let mtime: string = this.itemDesc.htime === '' ? '3' : this.itemDesc.htime
    this.isLoading = true;
    var regName = "テスト登録者";
    var remarks = "テスト登録中";
    var note = "備考は特にありません";
    var dnpComment = " ";
    var order_array: any[] = []

    let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))

    console.log('all orders', this.order)

    for (var $i = 0; $i < this.order.length; $i++) {
      order_array.push({
        item_no: this.order[$i].item_no,
        quantity: Number(this.order[$i].qty),
      });
    }
    console.log('order_array', order_array)

    if (this.editOrder.edit_order !== null) {
      let tmp2 = {
        'ref_num': this.editOrder.edit_order.order_record.order_ref_no,
        'order_items': order_array,
        'qty_update': this.toUpdate,
        'hoped_date': this.itemDesc.hdate,
        'hoped_time': Number(mtime),
        'person_in_charge': this.itemDesc.pic,
        'note': this.itemDesc.note,
        'user_id': tmp.userid,
        'dept_name': this.itemDesc.dept_name,
        'urg_flg': this.urgencyFlag == true ? 1 : 0,
      }
      console.log('tmp2', tmp2)
      this.orderService.updateOrder(tmp2).subscribe(res => {
        this.router.navigate(['../../../../main/dashboard/CC'])
      })
      return;
    }

    if (this.editOrder.bulk_order != null) {
      let observables = new Array();
      let isSave = false
      let refIds: any[] = []
      for (let itms of order_array) {
        observables.push(this.orderService.getItemById(itms.item_no).pipe(
          map(event => {
            itms.ttlBook = event.payload.item[0].booked_stock - (itms.quantity * (this.editOrder.bulk_order.length - 1))
          }),
          catchError((err: HttpErrorResponse) => {
            return Observable.throw(err);
          })
        ))
      }
      Observable.forkJoin(observables).subscribe(res => {
        for (let rowtmp of this.editOrder.bulk_order) {
          if (isSave) {
            let tmp_body = {
              'register_id': Number(tmp.userid),
              'register_name': regName,
              'urg_flg': this.urgencyFlag == true ? 1 : 0,
              'status': 9,
              'hoped_date': this.itemDesc.hdate,
              'hoped_time': Number(mtime),
              'addressee': this.itemDesc.addressee,
              'store_id': this.getGetStoreID(rowtmp[1]),
              'person_in_charge': this.itemDesc.pic,
              'remarks': remarks,
              'note': this.itemDesc.note,
              'dnp_comment': dnpComment,
              'order_item_array': order_array,
              'created_by': Number(tmp.userid),
              'esc_status': this.urgencyFlag == true ? 2 : 1,
              'esc_direction': 3,
              "dept_name": this.itemDesc.dept_name
            }
            observables.push(this.orderService.setOrder(tmp_body).pipe(
              map(event => {
                refIds.push(event.order_ref_code)
              }),
              catchError((err: HttpErrorResponse) => {
                console.log(err)
                return Observable.throw(err);
              })
            ))
          }
          isSave = true
        }
        Observable.forkJoin(observables).subscribe(res => {
          this.btnDisable = false
          observables = new Array();
          for (let tmpIds of refIds) {
            let body = {
              'head': 0,
              'reference': tmpIds,
              'type': 1,
              'user_type': 0,
              'user': tmp.userid,
              'level': 1,
              'user_id': tmp.userid,
              'cc_view': 1,
              'hd_view': 1,
              'wh_view': 1,
              'rr_view': 1,
            }
            observables.push(this.orderService.saveTask(body).pipe(
              map(event => {
                console.log('task Observables', 'Posting')
              }),
              catchError((err: HttpErrorResponse) => {
                console.log(err)
                return Observable.throw(err);
              })
            ))
          }
          Observable.forkJoin(observables).subscribe(res => {
            console.log('task Observables', 'Saved')

            this.sendNotif()
            this.router.navigate(['main/order/complete/' + this.id]);
          })
        })
      })
      return
    }

    this.queryOrder = {
      'register_id': Number(tmp.userid),
      'register_name': regName,
      'urg_flg': this.urgencyFlag == true ? 1 : 0,
      'status': 9,
      'hoped_date': this.itemDesc.hdate,
      'hoped_time': Number(mtime),
      'addressee': this.itemDesc.addressee,
      'store_id': this.store.store_no,
      'person_in_charge': this.itemDesc.pic,
      'remarks': remarks,
      'note': this.itemDesc.note,
      'dnp_comment': dnpComment,
      'order_item_array': order_array,
      'created_by': Number(tmp.userid),
      'esc_status': this.urgencyFlag == true ? 2 : 1,
      'esc_direction': 3,
      "dept_name": this.itemDesc.dept_name
    };

    this.orderService.setOrder(this.queryOrder).subscribe(
      res => {
        console.warn('setOrder', res);
        this.orderData.ref_code = res.payload[0].order_no;
        var body = {
          'head': 0,
          'reference': this.orderData.ref_code,
          'type': 1,
          'user_type': 0,
          'user': tmp.userid,
          'level': 1,
          'user_id': tmp.userid,
          'cc_view': 1,
          'hd_view': 1,
          'wh_view': 1,
          'rr_view': 1,
        }

        this.orderService.saveTask(body)
          .subscribe(ret => {
            if (this.relData.inq_ref.length == 0) {
              this.sendNotif()
              this.router.navigate(['main/order/complete/' + this.id]);
              return
            }
            if (this.relData.inq_ref.length !== 0 || this.relData.mainte_ref.length !== 0 || this.relData.coll_ref.length !== 0) {

              this.relData.order_ref = res.payload[0].order_no;
              let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
              let body = {
                'inquiry': this.relData.inq_ref,
                'order': this.relData.order_ref,
                'collection': this.relData.coll_ref,
                'maintenance': this.relData.mainte_ref,
                'created_by': tmp.userid,
                'id': this.relData.updID
              }

              if (this.relData.updID == null) {
                this.orderService.saveRelation(body)
                  .subscribe(res => {
                    let tmprefid: any = res
                    console.log('updID', tmprefid.body.insertId)
                    this.relData.updID = tmprefid.body.insertId
                    this.sendNotif()
                    this.router.navigate(['main/order/complete/' + this.id]);
                  })
              } else {
                console.log('UPDATING RELATION')
                this.orderService.updateRelation(body)
                  .subscribe(res => {
                    this.sendNotif()
                    this.router.navigate(['main/order/complete/' + this.id]);
                  })
              }
            } else {
              this.sendNotif()
              this.router.navigate(['main/order/complete/' + this.id]);
            }
          })
      }
    );
  }

  getGetStoreID(chain_store_id: string){
    for(let store of this.orderData.stores)
      if(store.chain_store_id === chain_store_id)
        return store.store_no
    return ''
  }

  sendNotif() {
    let view_tmp = {
      'cc': true,
      'hd': true,
      'wh': true,
      'rr': true,
      'gg': false,
      'vr': false
    }
    this.badgeService.pushBadgeNotif(view_tmp)
  }
}
